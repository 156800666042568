define("donor-elf-web/components/contact/details/properties/custom-property/edit-list-options", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/utils", "@glimmer/component", "@glimmer/tracking", "ember-changeset"], function (_exports, _component, _templateFactory, _object, _utils, _component2, _tracking, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <Form::Underline::Checkbox
      @changeset={{this.changeset}}
      @property='allowMultipleSelections'
      @label='Allow Multiple Selections'
      class='mb-2'
    />
    <div class='border rounded p-1 tw-h-40 overflow-auto'>
      {{#each this.options as |option index|}}
        {{!-- template-lint-disable require-input-label --}}
        <input
          value={{option.val}}
          placeholder='Enter option'
          class='form-underline-input pl-1 w-100 {{if (not-eq index 0) 'mt-1'}}'
          {{focus-on-insert}}
          {{on-enter (fn this.handleOptionEnter option index)}}
          {{on 'change' (fn this.handleInputChange option)}}
        />
      {{/each}}
      <div class='mt-2'>
        <button
          {{on 'click' this.addOption}}
          class='btn btn-sm py-0 btn-brand'
          type='button'
        >
          Add Option
        </button>
      </div>
      {{#if this.errorCantAddOption}}
        <div class='mt-2 tw-text-sm text-danger'>
          Fill out the last option before trying to add a new option
        </div>
      {{/if}}
    </div>
    {{#if this.errorNoOptions}}
      <div class='my-2 tw-text-sm text-danger'>
        Need to add at least 1 option
      </div>
    {{/if}}
    <div class='mt-2'>
      <button
        {{on 'click' this.save}}
        class='btn btn-sm py-0 btn-warning'
        type='button'
      >
        Save Options
      </button>
    </div>
  </div>
  */
  {
    "id": "WWJ2Uy2H",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"mb-2\"]],[[\"@changeset\",\"@property\",\"@label\"],[[30,0,[\"changeset\"]],\"allowMultipleSelections\",\"Allow Multiple Selections\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"border rounded p-1 tw-h-40 overflow-auto\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"      \"],[11,\"input\"],[16,2,[30,2,[\"val\"]]],[24,\"placeholder\",\"Enter option\"],[16,0,[29,[\"form-underline-input pl-1 w-100 \",[52,[28,[37,4],[[30,3],0],null],\"mt-1\"]]]],[4,[38,5],null,null],[4,[38,6],[[28,[37,7],[[30,0,[\"handleOptionEnter\"]],[30,2],[30,3]],null]],null],[4,[38,8],[\"change\",[28,[37,7],[[30,0,[\"handleInputChange\"]],[30,2]],null]],null],[12],[13],[1,\"\\n\"]],[2,3]],null],[1,\"    \"],[10,0],[14,0,\"mt-2\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-sm py-0 btn-brand\"],[24,4,\"button\"],[4,[38,8],[\"click\",[30,0,[\"addOption\"]]],null],[12],[1,\"\\n        Add Option\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"errorCantAddOption\"]],[[[1,\"      \"],[10,0],[14,0,\"mt-2 tw-text-sm text-danger\"],[12],[1,\"\\n        Fill out the last option before trying to add a new option\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"errorNoOptions\"]],[[[1,\"    \"],[10,0],[14,0,\"my-2 tw-text-sm text-danger\"],[12],[1,\"\\n      Need to add at least 1 option\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"mt-2\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm py-0 btn-warning\"],[24,4,\"button\"],[4,[38,8],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n      Save Options\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"option\",\"index\"],false,[\"form/underline/checkbox\",\"each\",\"-track-array\",\"if\",\"not-eq\",\"focus-on-insert\",\"on-enter\",\"fn\",\"on\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/custom-property/edit-list-options.hbs",
    "isStrictMode": false
  });

  let ContactDetailsPropertiesCustomPropertyEditListOptionsComponent = (_class = class ContactDetailsPropertiesCustomPropertyEditListOptionsComponent extends _component2.default {
    constructor(owner, args) {
      var _args$existingSetting, _args$existingSetting2;

      super(owner, args);

      _defineProperty(this, "changeset", void 0);

      _initializerDefineProperty(this, "options", _descriptor, this);

      _initializerDefineProperty(this, "errorCantAddOption", _descriptor2, this);

      _initializerDefineProperty(this, "errorNoOptions", _descriptor3, this);

      this.changeset = new _emberChangeset.default({
        allowMultipleSelections: !((_args$existingSetting = (_args$existingSetting2 = args.existingSettings) === null || _args$existingSetting2 === void 0 ? void 0 : _args$existingSetting2.single_selection) !== null && _args$existingSetting !== void 0 ? _args$existingSetting : false)
      });
      this.options = (args.existingOptions || []).map(x => {
        return {
          val: x
        };
      });

      if (this.options.length === 0) {
        this.addOption();
      }
    }

    addOption(event) {
      if (this.options.length > 0 && (0, _utils.isEmpty)(this.options[this.options.length - 1].val)) {
        this.errorCantAddOption = true;
      } else {
        this.errorCantAddOption = false;
        this.options = [...this.options, {
          val: ''
        }];
      }

      if (event) {
        // scroll the options div all the way down
        setTimeout(() => {
          let optionsDiv = event.target.closest('.overflow-auto');
          optionsDiv.scrollTop = optionsDiv.scrollHeight;
        });
      }
    }

    handleOptionEnter(option, index, event) {
      option.val = event.target.value;

      if (index === this.options.length - 1 && (0, _utils.isPresent)(option.val)) {
        this.addOption(event);
      }
    }

    handleInputChange(option, e) {
      option.val = e.target.value;
    }

    save() {
      let settings;

      if (this.changeset.get('allowMultipleSelections') === false) {
        settings = {
          single_selection: true
        };
      }

      let options = this.options.filter(x => (0, _utils.isPresent)(x.val)).map(x => x.val.trim());

      if (options.length === 0) {
        this.errorNoOptions = true;
      } else {
        this.args.onSave({
          options,
          settings
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorCantAddOption", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorNoOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOptionEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOptionEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInputChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInputChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = ContactDetailsPropertiesCustomPropertyEditListOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesCustomPropertyEditListOptionsComponent);
});