define("donor-elf-web/services/campaign-service", ["exports", "@ember/service", "pubsub-js", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/array-utils", "donor-elf-web/lib/store-utils"], function (_exports, _service, _pubsubJs, _tracking, _emberCachedDecoratorPolyfill, _arrayUtils, _storeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CAMPAIGN_CONTACTS_CHANGED_TOPIC = 'campaign-contacts-changed';
  let CampaignService = (_class = class CampaignService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "expiringMemoryCache", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "campaigns", _descriptor5, this);

      _initializerDefineProperty(this, "hostCampaigns", _descriptor6, this);

      _initializerDefineProperty(this, "campaignContacts", _descriptor7, this);

      _defineProperty(this, "_loadPromise", void 0);
    }

    get campaignsEnabled() {
      var _this$settings$fundSe;

      return (_this$settings$fundSe = this.settings.fundSettings) === null || _this$settings$fundSe === void 0 ? void 0 : _this$settings$fundSe.campaignsEnabled;
    }

    get campaignsById() {
      let byId = {};

      for (let campaign of this.campaigns) {
        byId[campaign.id] = campaign;
      }

      return byId;
    }

    get hostCampaignsById() {
      let byId = {};

      for (let hostCampaign of this.hostCampaigns) {
        byId[hostCampaign.id] = hostCampaign;
      }

      return byId;
    }

    get sortedCampaigns() {
      return (0, _arrayUtils.sortByProperties)(this.campaigns, ['name:asc']);
    }

    get sortedNonRestrictedCampaigns() {
      return this.sortedCampaigns.filter(c => !c.restricted);
    }

    get campaignContactsByContactId() {
      let byId = {};

      for (let x of this.campaignContacts) {
        if (!byId[x.contactId]) {
          byId[x.contactId] = [];
        }

        byId[x.contactId].push(x);
      }

      return byId;
    }

    load({
      forceReload
    } = {}) {
      var _this$fundsService$se;

      if (forceReload) {
        this._loadPromise = undefined;
      }

      if (((_this$fundsService$se = this.fundsService.selectedFund) === null || _this$fundsService$se === void 0 ? void 0 : _this$fundsService$se.id) === undefined || !this.campaignsEnabled) {
        this.campaigns = [];
        this.hostCampaigns = [];
        this._loadPromise = Promise.resolve();
      }

      if (this._loadPromise) {
        return this._loadPromise;
      } // intentionally not awaiting this


      this.ensureAllCampaignContactsLoaded();
      return this._loadPromise = this.store.findRecord('fund-campaigns-info', this.fundsService.selectedFund.id).then(record => {
        this.campaigns = record.campaigns.toArray();

        for (let campaign of this.campaigns) {
          this.setCampaignLabel(campaign, this.campaigns);
        }

        this.hostCampaigns = record.hostCampaigns.toArray();
      });
    }

    async ensureAllCampaignContactsLoaded() {
      let campaignContactsResult = await this.expiringMemoryCache.fetch({
        key: 'campaignContacts',
        expiresInMinutes: 10,
        loadValue: () => {
          return (0, _storeUtils.queryWithPages)({
            store: this.store,
            modelName: 'campaign-contact',
            queryOptions: {},
            pageSize: 1000
          });
        }
      });

      if (campaignContactsResult.fromCache) {
        // don't need to update the campaignContacts if they're still cached
        return;
      }

      this.campaignContacts = campaignContactsResult.value;

      _pubsubJs.default.publish(CAMPAIGN_CONTACTS_CHANGED_TOPIC);
    }

    async reloadCampaignContactsIfContactIdNotInCampaign(args) {
      var _this$campaignContact;

      if (((_this$campaignContact = this.campaignContactsByContactId[args.contactId]) === null || _this$campaignContact === void 0 ? void 0 : _this$campaignContact.find(x => x.campaignId === args.campaignId)) == null) {
        this.expiringMemoryCache.remove('campaignContacts');
      }

      return this.ensureAllCampaignContactsLoaded();
    }

    subscribeToCampaignContactsChanged(fn) {
      return _pubsubJs.default.subscribe(CAMPAIGN_CONTACTS_CHANGED_TOPIC, fn);
    }

    setCampaignLabel(campaign, allCampaigns) {
      if (campaign.label === '') {
        if (campaign.parentCampaignId) {
          let parentCampaign = allCampaigns.find(c => c.id === campaign.parentCampaignId);

          if (parentCampaign.label == '') {
            this.setCampaignLabel(parentCampaign, allCampaigns);
          }

          campaign.label = `${parentCampaign.label} > ${campaign.name}`;
        } else {
          campaign.label = campaign.name;
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "expiringMemoryCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "campaigns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hostCampaigns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "campaignContacts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "campaignsById", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "campaignsById"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hostCampaignsById", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "hostCampaignsById"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedCampaigns", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "sortedCampaigns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedNonRestrictedCampaigns", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "sortedNonRestrictedCampaigns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "campaignContactsByContactId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "campaignContactsByContactId"), _class.prototype)), _class);
  _exports.default = CampaignService;
});