define("donor-elf-web/controllers/funds/fund/communications/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "@ember/string", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/object-utils", "ember-parachute", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validators/date-or-empty"], function (_exports, _computed, _controller, _object, _utils, _service, _string, _dateUtils, _objectUtils, _emberParachute, _emberChangeset, _emberChangesetValidations, _dateOrEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const {
    Papa
  } = window;
  const queryParams = new _emberParachute.default({
    from: {
      defaultValue: '',
      refresh: true
    },
    to: {
      defaultValue: '',
      refresh: true
    },
    contactId: {
      defaultValue: '',
      refresh: true
    },
    page: {
      defaultValue: 1,
      refresh: true
    },
    pageSize: {
      defaultValue: 50,
      refresh: true
    }
  });
  _exports.queryParams = queryParams;
  const filterValidation = {
    from: (0, _dateOrEmpty.default)(),
    to: (0, _dateOrEmpty.default)()
  };

  var _default = _controller.default.extend(queryParams.Mixin, {
    formatDateService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    from: '',
    to: '',
    contactId: '',
    page: 1,
    pageSize: 50,
    filterExpanded: false,
    filter: null,
    filterChangeset: null,
    loading: false,
    downloading: false,
    downloadComplete: false,
    headerBarLinks: [{
      route: 'funds.fund.communications.index',
      models: [],
      label: 'All'
    }, {
      route: 'funds.fund.communications.by-contact',
      models: [],
      label: 'By Contact'
    }],
    queryParamsChanged: (0, _computed.or)('queryParamsState.{from,to,contactId,page,pageSize}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filter: null,
          filterChangeset: null,
          filterExpanded: false,
          downloading: false,
          downloadComplete: false
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.set)(this, 'loading', true);
      let filter = {
        offset: (queryParams.page - 1) * queryParams.pageSize,
        limit: queryParams.pageSize
      };
      let filterRecord = {
        page: queryParams.page,
        pageSize: queryParams.pageSize
      };

      if (!(0, _utils.isEmpty)(queryParams.from)) {
        filter.from = queryParams.from;
        filterRecord.from = _dateUtils.default.deserializeDateOnly(queryParams.from);
      }

      if (!(0, _utils.isEmpty)(queryParams.to)) {
        filter.to = queryParams.to;
        filterRecord.to = _dateUtils.default.deserializeDateOnly(queryParams.to);
      }

      if (!(0, _utils.isEmpty)(queryParams.contactId)) {
        filter.contactId = queryParams.contactId;
        filterRecord.contactId = queryParams.contactId;
      }

      let filterChangeset = new _emberChangeset.default(filterRecord, (0, _emberChangesetValidations.default)(filterValidation), filterValidation);
      (0, _object.setProperties)(this, {
        filter: filter,
        filterChangeset: filterChangeset
      });
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken().then(() => {
        return (0, _object.get)(this, 'store').query('communication', {
          filter: _objectUtils.default.decamelizeKeys(filter)
        }).then(records => {
          let totalCount = (0, _object.get)(records, 'meta.total_count');

          if ((0, _utils.isEmpty)(totalCount)) {
            totalCount = '0';
          }

          let totalPages = Math.ceil(Number(totalCount) / queryParams.pageSize);
          (0, _object.setProperties)(this, {
            communications: records,
            page: queryParams.page,
            totalPages: totalPages,
            loading: false
          });
        });
      });
    },

    filterMessage: (0, _object.computed)('from', 'to', 'contactId', function () {
      let messages = [];
      let {
        formatDateService,
        from,
        to,
        contactId
      } = (0, _object.getProperties)(this, 'formatDateService', 'from', 'to', 'contactId');

      if (!(0, _utils.isEmpty)(from)) {
        if (!(0, _utils.isEmpty)(to)) {
          messages.push(`Completed between ${formatDateService.format(from)} and ${formatDateService.format(to)}`);
        } else {
          messages.push(`Completed after ${formatDateService.format(from)}`);
        }
      } else if (!(0, _utils.isEmpty)(to)) {
        messages.push(`Completed before ${formatDateService.format(to)}`);
      }

      if (!(0, _utils.isEmpty)(contactId)) {
        let contact = (0, _object.get)(this, 'contactsCache').getById(contactId);

        if (contact) {
          messages.push(`For '${(0, _object.get)(contact, 'name')}'`);
        }
      }

      if (messages.length === 0) {
        return 'All communications';
      }

      return messages.join(', ');
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }, {
        label: 'Download',
        svgJar: 'filter',
        onClick: () => {
          this.send('download');
        }
      }];
    }),

    performDownload() {
      return; // this controller is no longer being usied
    },

    percentageDownloaded: (0, _object.computed)('downloadingPage', 'totalPages', function () {
      let {
        downloadingPage,
        totalPages
      } = this;
      let percent = (downloadingPage - 1) * 100 / totalPages;
      return (0, _string.htmlSafe)(`width: ${percent}%`);
    }),
    actions: {
      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          from: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'from'), {
            invalidValue: ''
          }),
          to: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'to'), {
            invalidValue: ''
          }),
          contactId: (0, _object.get)(filterChangeset, 'contactId') || '',
          page: 1
        });
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      },

      changePage(pageNumber) {
        (0, _object.set)(this, 'page', pageNumber);
      },

      download() {
        (0, _object.setProperties)(this, {
          downloading: true,
          downloadComplete: false,
          downloadingPage: 1,
          downloadedCommunications: []
        });
        this.performDownload();
      },

      finishedWithDownload() {
        (0, _object.setProperties)(this, {
          downloading: false,
          downloadComplete: false,
          downloadedCommunications: null
        });
      }

    }
  });

  _exports.default = _default;
});