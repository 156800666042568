define("donor-elf-web/components/dashboard-donut-chart-split", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    currency,
    Highcharts
  } = window; // colors: #f0ad4e (orange) | #0b68a1 (blue) | #d0eafb (light blue)

  let DashboardDonutChartSplit = (_class = class DashboardDonutChartSplit extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _defineProperty(this, "chartElement", void 0);

      _initializerDefineProperty(this, "tooltipLabel", _descriptor2, this);

      _initializerDefineProperty(this, "tooltipAmount", _descriptor3, this);

      _initializerDefineProperty(this, "tooltipPercent", _descriptor4, this);
    }

    get firstPercent() {
      var _this$args$firstPerce;

      return (_this$args$firstPerce = this.args.firstPercent) !== null && _this$args$firstPerce !== void 0 ? _this$args$firstPerce : 0;
    }

    get firstAmount() {
      var _this$args$firstAmoun;

      return (_this$args$firstAmoun = this.args.firstAmount) !== null && _this$args$firstAmoun !== void 0 ? _this$args$firstAmoun : 0;
    }

    get firstColor() {
      return this.args.firstColor;
    }

    get secondPercent() {
      var _this$args$secondPerc;

      return (_this$args$secondPerc = this.args.secondPercent) !== null && _this$args$secondPerc !== void 0 ? _this$args$secondPerc : 0;
    }

    get secondAmount() {
      var _this$args$secondAmou;

      return (_this$args$secondAmou = this.args.secondAmount) !== null && _this$args$secondAmou !== void 0 ? _this$args$secondAmou : 0;
    }

    get secondColor() {
      return this.args.secondColor;
    }

    get totalPercentColor() {
      return this.args.totalPercentColor || this.secondColor;
    }

    get hideAmount() {
      var _this$args$hideAmount;

      return (_this$args$hideAmount = this.args.hideAmount) !== null && _this$args$hideAmount !== void 0 ? _this$args$hideAmount : false;
    }

    get leftToRaisePercent() {
      let percent = 100 - this.firstPercent - this.secondPercent;

      if (percent < 0) {
        percent = 0;
      }

      return percent;
    }

    get totalPercent() {
      return this.firstPercent + this.secondPercent;
    }

    get totalAmount() {
      return currency(this.firstAmount).add(this.secondAmount).value;
    }

    get chartSettings() {
      let {
        firstPercent,
        firstAmount,
        secondPercent,
        secondAmount,
        leftToRaisePercent
      } = this;
      let self = this;

      if (firstPercent > 100) {
        firstPercent = 100;
      }

      if (firstPercent + secondPercent > 100) {
        secondPercent = 100 - firstPercent;
      }

      let fillerPercent = 100 - firstPercent - secondPercent;
      let titleText = `<span>${this.totalPercent}%</span>`;

      if (!this.hideAmount) {
        let formattedAmount = this.formatAmountService.format(this.totalAmount, {
          roundToDollar: true
        });
        titleText = `${titleText}<div style='font-size: 1rem; color: #636c72'>${formattedAmount}</div>`;
      }

      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0]
        },
        title: {
          text: titleText,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          style: {
            fontSize: '2rem',
            color: this.totalPercentColor,
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
          },
          y: this.hideAmount ? 7 : 0
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          }
        },
        tooltip: {
          formatter: function () {
            if (self.tooltipLabel !== this.point.name) {
              self.tooltipLabel = this.point.name;
              self.tooltipAmount = this.point.amount;
              self.tooltipPercent = this.point.percent;
            }

            return false;
          }
        },
        series: [{
          size: '100%',
          innerSize: '90%',
          dataLabels: {
            enabled: false
          },
          data: [{
            name: 'Given',
            y: firstPercent,
            color: this.firstColor,
            percent: firstPercent,
            amount: firstAmount
          }, {
            name: 'Expecting',
            y: secondPercent,
            color: this.secondColor,
            percent: secondPercent,
            amount: secondAmount
          }, {
            name: 'Left to Raise',
            y: fillerPercent,
            color: '#eceeef',
            percent: leftToRaisePercent,
            amount: this.args.leftToRaiseAmount
          }]
        }]
      };
    }

    handleClick() {
      var _this$args$onClick, _this$args;

      (_this$args$onClick = (_this$args = this.args).onClick) === null || _this$args$onClick === void 0 ? void 0 : _this$args$onClick.call(_this$args);
    }

    handleMouseout() {
      if (this.tooltipLabel !== undefined) {
        this.tooltipLabel = undefined;
      }
    }

    renderChart(el) {
      this.chartElement = el;
      Highcharts.chart(el, this.chartSettings);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tooltipLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tooltipAmount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tooltipPercent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype)), _class);
  _exports.default = DashboardDonutChartSplit;
});