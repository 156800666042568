define("donor-elf-web/controllers/funds/fund/donations-by-month", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/utils", "@ember/service", "donor-elf-web/lib/object-utils", "file-saver", "donor-elf-web/lib/array-utils", "donor-elf-web/lib/pledge-utils", "donor-elf-web/lib/filter-utils-new"], function (_exports, _controller, _object, _tracking, _emberCachedDecoratorPolyfill, _utils, _service, _objectUtils, _fileSaver, _arrayUtils, _pledgeUtils, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Papa,
    moment
  } = window;
  const defaultSortBy = ['contact.nameLowerCase:asc'];
  let TableRow = (_class = class TableRow {
    constructor(args) {
      _defineProperty(this, "contact", void 0);

      _initializerDefineProperty(this, "pledges", _descriptor, this);

      _defineProperty(this, "monthAmounts", void 0);

      _defineProperty(this, "total", void 0);

      this.contact = args.contact;
      this.pledges = args.pledges;
      this.monthAmounts = args.monthAmounts;
      this.total = args.total;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pledges", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let DonationsByMonthController = (_class3 = class DonationsByMonthController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor2, this);

      _initializerDefineProperty(this, "campaignService", _descriptor3, this);

      _initializerDefineProperty(this, "contactsCache", _descriptor4, this);

      _initializerDefineProperty(this, "contactsExport", _descriptor5, this);

      _initializerDefineProperty(this, "formatDateService", _descriptor6, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor7, this);

      _initializerDefineProperty(this, "fundsService", _descriptor8, this);

      _initializerDefineProperty(this, "media", _descriptor9, this);

      _initializerDefineProperty(this, "settings", _descriptor10, this);

      _initializerDefineProperty(this, "store", _descriptor11, this);

      _defineProperty(this, "queryParams", ['filters']);

      _initializerDefineProperty(this, "filters", _descriptor12, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor13, this);

      _initializerDefineProperty(this, "loading", _descriptor14, this);

      _initializerDefineProperty(this, "error", _descriptor15, this);

      _initializerDefineProperty(this, "rows", _descriptor16, this);

      _initializerDefineProperty(this, "tableColumns", _descriptor17, this);

      _initializerDefineProperty(this, "months", _descriptor18, this);

      _initializerDefineProperty(this, "totalRow", _descriptor19, this);

      _defineProperty(this, "savePledgeSubsciptionToken", void 0);

      _initializerDefineProperty(this, "sortBy", _descriptor20, this);
    }

    get sortedRows() {
      return (0, _arrayUtils.sortByProperties)(this.rows, this.sortBy);
    }

    templateDidInsert() {
      if ((0, _utils.isEmpty)(this.filters)) {
        this.filters = (0, _filterUtilsNew.serializeAppliedFilters)([this.defaultDateRangeAppliedFilter]);
      }

      this.deserializeFilters();
      this.load();

      if (this.savePledgeSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.savePledgeSubsciptionToken);
      }

      this.savePledgeSubsciptionToken = this.addSomethingModal.subscribeToPledgeSaved(this.handlePledgeSaved);
    }

    templateWillDestroy() {
      this.loading = true;
      this.filters = undefined;
      this.appliedFilters = undefined;
      this.error = null;
      this.months = undefined;
      this.rows = [];
      this.tableColumns = [];
      this.sortBy = defaultSortBy.slice();

      if (this.savePledgeSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.savePledgeSubsciptionToken);
      }
    }

    get defaultDateRangeAppliedFilter() {
      let from = this.fundGoalService.hasYtdGoalType ? this.fundGoalService.ytdStartMoment.format('YYYY-MM-DD') : moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD');
      let to = moment().endOf('month').format('YYYY-MM-DD');
      return {
        propertyName: 'dateRange',
        operation: '><',
        val: [from, to]
      };
    }

    get dateRangeAppliedFilter() {
      var _this$appliedFilters;

      let appliedFilter = (_this$appliedFilters = this.appliedFilters) === null || _this$appliedFilters === void 0 ? void 0 : _this$appliedFilters.find(x => x.propertyName === 'dateRange');
      return {
        from: appliedFilter.val[0],
        to: appliedFilter.val[1]
      };
    }

    get availablePropertyFilters() {
      let list = [];
      list.push({
        propertyName: 'dateRange',
        label: 'Date',
        propertyType: 'date',
        availableOperations: ['><'],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          if (appliedFilter.operation === '><') {
            let dates = appliedFilter.val;
            queryFilter.from = dates[0];
            queryFilter.to = dates[1];
          }
        }
      });

      if (this.campaignService.campaignsEnabled) {
        list.push({
          propertyName: 'campaignId',
          label: 'Campaign',
          propertyType: 'list',
          availableOperations: ['any', 'absent', 'present'],
          listOptions: this.campaignService.campaigns.sortBy('name').map(x => {
            return {
              val: x.id,
              label: x.name
            };
          }),
          applyToQueryFilter: async (appliedFilter, queryFilter) => {
            if (appliedFilter.operation === 'any') {
              queryFilter.campaignIds = appliedFilter.val;
            } else if (appliedFilter.operation === 'absent') {
              queryFilter.campaignIds = 'absent';
            } else if (appliedFilter.operation === 'present') {
              queryFilter.campaignIds = 'present';
            }
          }
        });
      }

      let contactFilterInfos = this.contactsCache.availablePropertyFiltersForContacts.filter(x => x.custom.includeOnAnyReport && x.propertyName !== 'campaignIds');

      for (let filterInfo of contactFilterInfos) {
        list.push({
          propertyName: filterInfo.propertyName,
          label: `Contact ${filterInfo.label}`,
          propertyType: filterInfo.propertyType,
          availableOperations: filterInfo.availableOperations,
          listOptions: filterInfo.listOptions,
          listIsSingleSelection: filterInfo.listIsSingleSelection,
          applyToQueryFilter: async (appliedFilter, queryFilter) => {
            let contactIds = (await this.contactsCache.filter([appliedFilter])).map(x => x.id);

            if (contactIds.length === 0) {
              contactIds = ["-1"]; // since no records match the contact filter, force the api to return 0 records
            }

            queryFilter.contactIds = (0, _filterUtilsNew.ContactIdsIntersection)(queryFilter.contactIds, contactIds);
          }
        });
      }

      return list;
    }

    async load() {
      try {
        this.loading = true;
        this.error = null;
        let {
          dateRangeAppliedFilter
        } = this;
        let queryFilter = {};
        let {
          availablePropertyFilters
        } = this;

        for (let appliedFilter of this.appliedFilters || []) {
          let propertyFilter = availablePropertyFilters.find(x => x.propertyName === appliedFilter.propertyName);
          await propertyFilter.applyToQueryFilter(appliedFilter, queryFilter);
        }

        let pledgesFilter = {
          startDate: dateRangeAppliedFilter.from,
          endDate: dateRangeAppliedFilter.to
        };
        let pledges = (await this.store.query('pledge', {
          filter: _objectUtils.default.decamelizeKeys(pledgesFilter),
          sort: '-start_date'
        })).toArray();
        let pledgesByContactId = {};

        for (let pledge of pledges) {
          if (pledgesByContactId[pledge.contactId] == null) {
            pledgesByContactId[pledge.contactId] = [];
          }

          pledgesByContactId[pledge.contactId].push(pledge);
        }

        let records = (await this.store.query('donations-by-month', {
          filter: _objectUtils.default.decamelizeKeys(queryFilter)
        })).toArray();
        let byContactId = {};
        let totalByMonth = {
          overall: 0
        };
        let totalByContact = {};

        for (let record of records) {
          let {
            contactId,
            year,
            month,
            amount
          } = record;

          if (this.contactsCache.getById(contactId)) {
            byContactId[contactId] = byContactId[contactId] || {};
            byContactId[contactId][`${year}-${month}`] = amount;
            totalByMonth[`${year}-${month}`] = (totalByMonth[`${year}-${month}`] || 0) + amount;
            totalByMonth.overall += amount;
            totalByContact[contactId] = (totalByContact[contactId] || 0) + amount;
          }
        }

        let months = [];
        let date = moment(dateRangeAppliedFilter.from);
        let to = moment(dateRangeAppliedFilter.to);

        while (date < to) {
          months.push({
            year: date.format('YYYY'),
            month: date.format('M'),
            label: date.format('MMM YYYY')
          });
          date.add(1, 'month');
        }

        this.months = months;
        this.totalRow = [{
          totalMonthAmounts: months.map(item => {
            return totalByMonth[`${item.year}-${item.month}`];
          }),
          overallTotal: totalByMonth.overall
        }];
        let rows = [];

        for (let contactId in byContactId) {
          rows.push(new TableRow({
            contact: this.contactsCache.getById(contactId),
            pledges: pledgesByContactId[contactId],
            monthAmounts: months.map(item => {
              return byContactId[contactId][`${item.year}-${item.month}`];
            }),
            total: totalByContact[contactId]
          }));
        }

        this.rows = rows;
        this.loading = false;
        this.setTableColumns();
      } catch (error) {
        this.error = error;
      }
    }

    setTableColumns() {
      let tableColumns = [];
      tableColumns.push({
        name: 'Contact',
        isFixed: 'left',
        width: this.media.isSmallDown ? 100 : 200,
        isContactColumn: true,
        sortBy: defaultSortBy
      });
      this.months.forEach((month, index) => {
        tableColumns.push({
          name: month.label,
          month,
          width: 125,
          index: index,
          sortBy: [`monthAmounts.${index}`]
        });
      });
      tableColumns.push({
        name: 'Total',
        isTotalColumn: true,
        width: 125,
        sortBy: ['total']
      });
      this.tableColumns = tableColumns;
    }

    handleFiltersChanged(currentFilters) {
      // only allow one dateRange filter
      if (currentFilters.filter(x => x.propertyName === 'dateRange').length > 1) {
        currentFilters = [currentFilters.find(x => x.propertyName === 'dateRange'), ...currentFilters.filter(x => x.propertyName !== 'dateRange')];
      } // confirm a dateRange filter is present


      if (!currentFilters.some(x => x.propertyName === 'dateRange')) {
        currentFilters.push(this.defaultDateRangeAppliedFilter);
      } // confirm dateRange 'from' date is always at the beginning of the month
      // and the 'to' date is always at the end of the month


      let dateRangeFilter = currentFilters.find(x => x.propertyName === 'dateRange');
      let dateRangeVals = dateRangeFilter.val;
      dateRangeFilter.val = [moment(dateRangeVals[0]).startOf('month').format('YYYY-MM-DD'), moment(dateRangeVals[1]).endOf('month').format('YYYY-MM-DD')];
      this.appliedFilters = currentFilters;
      this.serializeFilters();
      this.load();
    }

    serializeFilters() {
      this.filters = (0, _filterUtilsNew.serializeAppliedFilters)(this.appliedFilters);
    }

    deserializeFilters() {
      this.appliedFilters = (0, _filterUtilsNew.deserializeAppliedFilters)(this.filters);
    }

    get actionsMenuItems() {
      return [{
        label: 'Download',
        svgJar: 'download',
        onClick: this.download
      }];
    }

    applySortByColumn(column) {
      if (this.sortBy.join(',') === column.sortBy.join(',')) {
        this.sortBy[0] = `${this.sortBy[0]}:desc`;
        this.sortBy = [...this.sortBy];
      } else {
        this.sortBy = column.sortBy.slice();
      }
    }

    addPledge(row) {
      let lastMonthAmount = row.monthAmounts.filter(x => x > 0).pop();
      this.addSomethingModal.open({
        type: 'pledge',
        pledgeOptions: {
          contactId: row.contact.id,
          amount: lastMonthAmount,
          frequency: _pledgeUtils.pledgeMonthlyFrequency
        }
      });
    }

    async handlePledgeSaved(_msg, data) {
      if (data.newPledge) {
        let row = this.rows.find(x => x.contact.id === data.pledge.contactId);

        if (row) {
          row.pledges = [...(row.pledges || []), data.pledge];
        }
      }
    }

    async download() {
      let rows = [];
      let headerRow = ['Name'];
      this.months.forEach(month => {
        headerRow.push(month.label);
      });
      headerRow.push('Total');
      headerRow.push('---');
      let headerInfo = await this.contactsExport.addContactsHeaderColumns(headerRow, this.contactsCache.contacts);
      rows.push(headerRow);

      for (let item of this.sortedRows) {
        var _item$contact;

        let row = [(_item$contact = item.contact) === null || _item$contact === void 0 ? void 0 : _item$contact.name];

        for (let i = 0; i < this.months.length; i++) {
          row.push(item.monthAmounts[i]);
        }

        row.push(item.total);
        row.push('');
        await this.contactsExport.addContactInfoToRow(row, item.contact, headerInfo);
        rows.push(row);
      }

      rows.push([]);
      let csvTotalRow = ['Total'];
      this.totalRow[0].totalMonthAmounts.forEach(total => {
        csvTotalRow.push(total);
      });
      csvTotalRow.push(this.totalRow[0].overallTotal);
      rows.push(csvTotalRow);
      this.store.createRecord('contact-download').save();
      let csv = Papa.unparse(rows);
      var blob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
      });

      _fileSaver.default.saveAs(blob, 'donations-by-month.csv', true);
    }

    adjustTableContainerHeight(el) {
      let newHeight = window.innerHeight - (el.getBoundingClientRect().top + window.scrollY) - 20;
      el.style.height = `${newHeight}px`;
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "contactsExport", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "appliedFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "rows", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class3.prototype, "tableColumns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class3.prototype, "months", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class3.prototype, "totalRow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class3.prototype, "sortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return defaultSortBy;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateDidInsert"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateWillDestroy"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "availablePropertyFilters", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class3.prototype, "availablePropertyFilters"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "load", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "load"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleFiltersChanged", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleFiltersChanged"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "applySortByColumn", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "applySortByColumn"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "addPledge", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "addPledge"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handlePledgeSaved", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handlePledgeSaved"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "download"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "adjustTableContainerHeight", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "adjustTableContainerHeight"), _class3.prototype)), _class3);
  _exports.default = DonationsByMonthController;
});