define("donor-elf-web/controllers/funds/fund/groups/group/index", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "file-saver", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _object, _tracking, _service, _fileSaver, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Papa
  } = window;
  let GroupIndexController = (_class = class GroupIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "contactsCache", _descriptor2, this);

      _initializerDefineProperty(this, "contactsExport", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "settings", _descriptor5, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "q", _descriptor6, this);
    }

    get filterApplied() {
      return this.q.length > 2;
    }

    get sortedContacts() {
      return (0, _arrayUtils.sortByProperties)(this.model.contacts, ['name:asc']);
    }

    get filteredContacts() {
      let {
        sortedContacts
      } = this;
      let q = this.q.trim();

      if (q.length < 2) {
        return sortedContacts;
      }

      let matchingContactIds = {};
      this.contactsCache.search(q).forEach(contact => {
        matchingContactIds[contact.id] = true;
      });
      return sortedContacts.filter(contact => {
        return matchingContactIds[contact.id];
      });
    }

    templateDidInsert() {
      this.contactsCache.nextPreviousContactIds = this.sortedContacts.map(x => x.id);
    }

    templateWillDestroy() {
      this.q = '';
    }

    get actionMenuItems() {
      let menuItems = [{
        label: 'Add to Group',
        svgJar: 'plus',
        route: 'funds.fund.groups.group.add'
      }, {
        label: 'Edit Group Name',
        svgJar: 'edit',
        route: 'funds.fund.groups.group.edit-name'
      }, {
        label: 'Delete Group',
        svgJar: 'trash',
        route: 'funds.fund.groups.group.delete'
      }];

      if (this.settings.hasMailchimpFundSync) {
        menuItems.push({
          label: 'Sync with MailChimp',
          svgJar: 'plus',
          onClick: () => {
            this.router.transitionTo('funds.fund.groups.group.add-to-mailchimp');
          }
        });
      }

      menuItems.push({
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.download();
        }
      });
      return menuItems;
    }

    updateFilter(e) {
      this.q = e.target.value.trim();
    }

    handleRemovedFromGroup(contact) {
      this.model.contacts.removeObject(contact);
    }

    async download() {
      let rows = [];
      let headerRow = [];
      let headerInfo = await this.contactsExport.addContactsHeaderColumns(headerRow, this.contactsCache.contacts);
      rows.push(headerRow);

      for (let contact of this.model.contacts) {
        let row = [];
        await this.contactsExport.addContactInfoToRow(row, contact, headerInfo);
        rows.push(row);
      }

      let csv = Papa.unparse(rows);
      var blob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
      });

      _fileSaver.default.saveAs(blob, `${this.model.group}-contacts.csv`, true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactsExport", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleRemovedFromGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRemovedFromGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype)), _class);
  _exports.default = GroupIndexController;
});