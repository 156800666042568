define("donor-elf-web/components/fund-upload-progress", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/string", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _service, _string, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='progress'
    ...attributes
    {{did-update this.getProgress @check}}
  >
    <div class='progress-bar {{this.progressBarBackgroundClass}}' style={{this.progressBarStyle}}></div>
  </div>
  
  */
  {
    "id": "n7R62RAO",
    "block": "[[[11,0],[24,0,\"progress\"],[17,1],[4,[38,0],[[30,0,[\"getProgress\"]],[30,2]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"progress-bar \",[30,0,[\"progressBarBackgroundClass\"]]]]],[15,5,[30,0,[\"progressBarStyle\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@check\"],false,[\"did-update\"]]",
    "moduleName": "donor-elf-web/components/fund-upload-progress.hbs",
    "isStrictMode": false
  });

  let FundUploadProgressComponent = (_class = class FundUploadProgressComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "timeoutId", void 0);

      _defineProperty(this, "last10PercentTimeoutId", void 0);

      _defineProperty(this, "showingLast10PercentProgress", false);

      _initializerDefineProperty(this, "progress", _descriptor3, this);

      _initializerDefineProperty(this, "completed", _descriptor4, this);

      _initializerDefineProperty(this, "hasError", _descriptor5, this);

      this.getProgress();
    }

    get check() {
      var _this$args$check;

      return (_this$args$check = this.args.check) !== null && _this$args$check !== void 0 ? _this$args$check : true;
    }

    get onFirst5PercentProgress() {
      return this.progress == null || this.progress <= 5;
    }

    get onLast10PercentProgress() {
      return (this.progress || 0) >= 90;
    }

    get progressBarStyle() {
      if (this.progress) {
        return (0, _string.htmlSafe)(`width: ${this.progress}%`);
      } else {
        return (0, _string.htmlSafe)('');
      }
    }

    get progressBarBackgroundClass() {
      if (this.hasError) {
        return 'bg-danger';
      }

      if (this.completed) {
        return 'bg-success';
      }

      return 'bg-brand';
    }

    async getProgress() {
      if (!this.check) {
        return;
      }

      this.timeoutId = undefined;
      let model = await this.store.findRecord('fund-upload', this.fundsService.selectedFund.id, {
        reload: true
      });

      if (model.hasError) {
        var _this$args$onError, _this$args;

        (_this$args$onError = (_this$args = this.args).onError) === null || _this$args$onError === void 0 ? void 0 : _this$args$onError.call(_this$args);
        return;
      }

      if (!this.onLast10PercentProgress) {
        this.progress = Math.max(model.progress || 0, 5);
      }

      if (model.progress === 100) {
        this.completed = true;
        this.args.onComplete();
        return;
      }

      if (!this.onLast10PercentProgress) {
        var _this$args$onProgress, _this$args2;

        (_this$args$onProgress = (_this$args2 = this.args).onProgress) === null || _this$args$onProgress === void 0 ? void 0 : _this$args$onProgress.call(_this$args2, this.progress);
      }

      if (this.onLast10PercentProgress && !this.showingLast10PercentProgress) {
        this.showingLast10PercentProgress = true;
        this.delayLast10PercentProgress();
      }

      if (this.check) {
        this.delayGetProgress();
      }
    }

    delayGetProgress() {
      this.timeoutId = window.setTimeout(this.getProgress, 3000);
    }

    async showLast10PercentProgress() {
      let newProgress = this.progress + 1;

      if (newProgress === 99) {
        newProgress = 90;
      }

      this.progress = newProgress;

      if (this.check) {
        this.delayLast10PercentProgress();
      }
    }

    delayLast10PercentProgress() {
      this.last10PercentTimeoutId = window.setTimeout(this.showLast10PercentProgress, 2500);
    }

    willDestroy() {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
      }

      if (this.last10PercentTimeoutId) {
        window.clearTimeout(this.last10PercentTimeoutId);
        this.last10PercentTimeoutId = undefined;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "progress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "completed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showLast10PercentProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showLast10PercentProgress"), _class.prototype)), _class);
  _exports.default = FundUploadProgressComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FundUploadProgressComponent);
});