define("donor-elf-web/components/left-border-card", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='d-flex rounded-right' ...attributes>
    <div class='tw-w-1 flex-shrink-0 {{this.leftBorderColor}}'></div>
    <div class='flex-grow-1 rounded-right border-top border-right border-bottom'>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "e9UNCLEM",
    "block": "[[[11,0],[24,0,\"d-flex rounded-right\"],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"tw-w-1 flex-shrink-0 \",[30,0,[\"leftBorderColor\"]]]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-1 rounded-right border-top border-right border-bottom\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "donor-elf-web/components/left-border-card.hbs",
    "isStrictMode": false
  });

  class LeftBorderCardComponent extends _component2.default {
    get leftBorderColor() {
      var _this$args$leftBorder;

      return (_this$args$leftBorder = this.args.leftBorderColor) !== null && _this$args$leftBorder !== void 0 ? _this$args$leftBorder : 'bg-brand';
    }

  }

  _exports.default = LeftBorderCardComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LeftBorderCardComponent);
});