define("donor-elf-web/controllers/funds/fund/tasks/v2/index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/string", "donor-elf-web/lib/object-utils", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/filter-utils-new", "@ember/template", "donor-elf-web/lib/store-utils", "file-saver", "@ember/utils"], function (_exports, _controller, _object, _service, _string, _objectUtils, _tracking, _emberCachedDecoratorPolyfill, _filterUtilsNew, _template, _storeUtils, _fileSaver, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Papa
  } = window;
  let DEFAULT_FILTER = '[{"propertyName":"completed","operation":"=","val":"Not Complete"}]';
  let PAGE_SIZE = 50;
  let TasksV2Controller = (_class = class TasksV2Controller extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "contactsExport", _descriptor2, this);

      _initializerDefineProperty(this, "formatDateService", _descriptor3, this);

      _initializerDefineProperty(this, "media", _descriptor4, this);

      _initializerDefineProperty(this, "taskService", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _defineProperty(this, "queryParams", ['page', 'filters']);

      _initializerDefineProperty(this, "filters", _descriptor7, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor8, this);

      _initializerDefineProperty(this, "page", _descriptor9, this);

      _initializerDefineProperty(this, "sortBy", _descriptor10, this);

      _initializerDefineProperty(this, "sortDir", _descriptor11, this);

      _initializerDefineProperty(this, "tasks", _descriptor12, this);

      _initializerDefineProperty(this, "totalCount", _descriptor13, this);

      _initializerDefineProperty(this, "totalPages", _descriptor14, this);

      _initializerDefineProperty(this, "loading", _descriptor15, this);

      _initializerDefineProperty(this, "downloading", _descriptor16, this);

      _initializerDefineProperty(this, "downloadComplete", _descriptor17, this);

      _initializerDefineProperty(this, "downloadPercentStyle", _descriptor18, this);
    }

    templateDidInsert() {
      if ((0, _utils.isEmpty)(this.filters)) {
        this.filters = DEFAULT_FILTER;
      }

      this.deserializeFilters();
      this.load();
    }

    templateWillDestroy() {
      this.tasks = [];
      this.page = 1;
      this.sortBy = 'dueDate';
      this.sortDir = 'asc';
      this.loading = true;
      this.filters = undefined;
      this.appliedFilters = undefined;
      this.downloading = false;
      this.downloadComplete = false;
      this.downloadPercentStyle = undefined;
    }

    async load() {
      var _records$meta;

      this.loading = true;
      let queryFilter = {
        offset: (this.page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE
      };
      let {
        availablePropertyFilters
      } = this;

      for (let appliedFilter of this.appliedFilters || []) {
        let propertyFilter = availablePropertyFilters.find(x => x.propertyName === appliedFilter.propertyName);
        await propertyFilter.applyToQueryFilter(appliedFilter, queryFilter);
      } // if the filter excludes all contacts so pass in an invalid contact id to get no results


      if (queryFilter.contactId && queryFilter.contactId.length === 0) {
        queryFilter.contactId = ['none'];
      }

      let sort = (0, _string.decamelize)(this.sortBy);

      if (this.sortDir === 'desc') {
        sort = `-${sort}`;
      }

      let records = await this.store.query('v2-task', {
        filter: _objectUtils.default.decamelizeKeys(queryFilter),
        sort: sort
      });
      this.totalCount = Number(((_records$meta = records.meta) === null || _records$meta === void 0 ? void 0 : _records$meta.total_count) || 0);
      this.totalPages = Math.ceil(this.totalCount / PAGE_SIZE);
      this.tasks = records.toArray();
      this.loading = false;
    }

    get availablePropertyFilters() {
      let filters = [];
      filters.push({
        propertyName: 'completed',
        label: 'Completed Status',
        propertyType: 'string',
        listOptions: ['Completed', 'Not Complete'],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.completed = appliedFilter.val;
        }
      });
      filters.push({
        propertyName: 'completedDate',
        label: 'Completed Date',
        propertyType: 'date',
        availableOperations: ['>=', '<=', '='],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          if (appliedFilter.operation === '>=') {
            queryFilter.completed_from = appliedFilter.val;
          } else if (appliedFilter.operation === '<=') {
            queryFilter.completed_to = appliedFilter.val;
          } else {
            queryFilter.completed_from = appliedFilter.val;
            queryFilter.completed_to = appliedFilter.val;
          }
        }
      });
      filters.push({
        propertyName: 'due',
        label: 'Due',
        propertyType: 'string',
        listOptions: [{
          label: 'Today and Overdue',
          val: 'today-and-overdue'
        }, {
          label: 'Overdue',
          val: 'overdue'
        }, {
          label: 'Today',
          val: 'today'
        }, {
          label: 'Upcoming',
          val: 'upcoming'
        }, {
          label: 'Within a Month',
          val: 'within-a-month'
        }, {
          label: 'No Due Date',
          val: 'none'
        }],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.due = appliedFilter.val;
        }
      });
      filters.push({
        propertyName: 'action',
        label: 'Action',
        propertyType: 'list',
        availableOperations: ['any'],
        listOptions: this.taskService.actions.map(x => x.label),
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          let actionLabels = appliedFilter.val;
          queryFilter.actionId = actionLabels.map(x => this.taskService.actionsByLabel[x].id);
        }
      });
      filters.push({
        propertyName: 'medium',
        label: 'Medium',
        propertyType: 'list',
        availableOperations: ['any'],
        listOptions: this.taskService.mediums.map(x => x.label),
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          let mediumLabels = appliedFilter.val;
          queryFilter.mediumId = mediumLabels.map(x => this.taskService.mediumsByLabel[x].id);
        }
      });
      filters.push({
        propertyName: 'contactIds',
        label: 'Contact',
        propertyType: 'contactIds',
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.contactId = (0, _filterUtilsNew.ContactIdsIntersection)(queryFilter.contactId, appliedFilter.val);
        }
      });

      for (let filterInfo of this.contactsCache.availablePropertyFiltersForContacts.filter(x => x.custom.includeOnAnyReport)) {
        filters.push({
          propertyName: filterInfo.propertyName,
          label: `Contact ${filterInfo.label}`,
          propertyType: filterInfo.propertyType,
          availableOperations: filterInfo.availableOperations,
          listOptions: filterInfo.listOptions,
          listIsSingleSelection: filterInfo.listIsSingleSelection,
          applyToQueryFilter: async (appliedFilter, queryFilter) => {
            let contactIds = (await this.contactsCache.filter([appliedFilter])).map(x => x.id);
            queryFilter.contactId = (0, _filterUtilsNew.ContactIdsIntersection)(queryFilter.contactId, contactIds);
          }
        });
      }

      return filters;
    }

    get actionsMenuItems() {
      return [{
        label: 'Download',
        svgJar: 'download',
        onClick: this.download
      }];
    }

    handleFiltersChanged(currentFilters) {
      this.page = 1;
      this.appliedFilters = currentFilters;
      this.serializeFilters();
      this.load();
    }

    serializeFilters() {
      this.filters = (0, _filterUtilsNew.serializeAppliedFilters)(this.appliedFilters);
    }

    deserializeFilters() {
      this.appliedFilters = (0, _filterUtilsNew.deserializeAppliedFilters)(this.filters);
    }

    get displayOneColumn() {
      return this.media.isMobile;
    }

    handleTaskDeleted(task) {
      this.tasks = this.tasks.filter(x => x.id !== task.id);
    }

    changePage(pageNumber) {
      this.page = pageNumber;
      this.load();
    }

    finishedWithDownload() {
      this.downloading = false;
      this.downloadComplete = false;
    }

    async download() {
      this.downloading = true;
      this.downloadComplete = false;
      this.downloadPercentStyle = (0, _template.htmlSafe)('width: 5%');
      let queryFilter = {};
      let {
        availablePropertyFilters
      } = this;

      for (let appliedFilter of this.appliedFilters || []) {
        let propertyFilter = availablePropertyFilters.find(x => x.propertyName === appliedFilter.propertyName);
        await propertyFilter.applyToQueryFilter(appliedFilter, queryFilter);
      } // if the filter excludes all contacts so pass in an invalid contact id to get no results


      if (queryFilter.contactId && queryFilter.contactId.length === 0) {
        queryFilter.contactId = ['none'];
      }

      let downloadedTasks = await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'v2-task',
        queryOptions: {
          filter: queryFilter
        },
        pageSize: 100,
        pageCallback: args => {
          let percentageDownloaded = Math.round(args.downloadedCount * 100 / args.totalCount);

          if (percentageDownloaded < 5) {
            percentageDownloaded = 5;
          }

          this.downloadPercentStyle = (0, _template.htmlSafe)(`width: ${percentageDownloaded}%`);
        }
      });
      this.downloadPercentStyle = (0, _template.htmlSafe)(`width: 100%`);
      let headerRow = ['Name', 'Action', 'Medium', 'Summary', 'Notes', 'Due', 'Completed', ''];
      let headerInfo = await this.contactsExport.addContactsHeaderColumns(headerRow, this.contactsCache.contacts);
      let rows = [headerRow];

      for (let task of downloadedTasks) {
        let contact = this.contactsCache.contactsById[task.contactId];
        let row = [contact === null || contact === void 0 ? void 0 : contact.name, this.taskService.actionLabelsById[task.actionId], this.taskService.mediumLabelsById[task.mediumId], task.summary, task.notes, this.formatDateService.formatForFile(task.dueDate), this.formatDateService.formatForFile(task.completedDate), ''];
        await this.contactsExport.addContactInfoToRow(row, contact, headerInfo);
        rows.push(row);
      }

      this.store.createRecord('contact-download').save();
      let csv = Papa.unparse(rows);
      var blob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
      });

      _fileSaver.default.saveAs(blob, 'tasks.csv', true);

      this.downloadComplete = true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactsExport", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appliedFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "sortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'dueDate';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sortDir", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'asc';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "tasks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "totalPages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "downloading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "downloadComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "downloadPercentStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "availablePropertyFilters", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "availablePropertyFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFiltersChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFiltersChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finishedWithDownload", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "finishedWithDownload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype)), _class);
  _exports.default = TasksV2Controller;
});