define("donor-elf-web/helpers/filter", ["exports", "@ember/component/helper", "@ember/debug", "donor-elf-web/lib/filter-utils-new"], function (_exports, _helper, _debug, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // usage: {{filter-query (filter 'firstName' operation='stringContains' val='yoder')}}
  // usage: {{filter-query (filter 'contact_group' operation='any' val=(array 'Friend' 'Church'))}}
  var _default = (0, _helper.helper)(function (params, namedArgs) {
    let propertyName = params[0];
    let operation = namedArgs.operation;
    let val = namedArgs.val;
    (false && !(operation == null || _filterUtilsNew.OperationLabels[operation] != null) && (0, _debug.assert)(`'${operation}' is not a valid filter operation`, operation == null || _filterUtilsNew.OperationLabels[operation] != null)); // convert val:string to val:string[] if it's an operation for a list

    if (operation === 'any' || operation === 'all' || operation === 'none') {
      if (typeof val === 'string') {
        val = [val];
      }
    }

    let appliedPropertyFilter = {
      propertyName,
      operation,
      val
    };
    console.log({
      appliedPropertyFilter
    });
    return appliedPropertyFilter; // return {
    //   propertyName,
    //   operation,
    //   val
    // };
  });

  _exports.default = _default;
});