define("donor-elf-web/services/contacts-cache", ["exports", "@ember/service", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/utils", "donor-elf-web/lib/array-utils", "donor-elf-web/lib/store-utils", "donor-elf-web/lib/filter-utils-new", "fuzzysort"], function (_exports, _service, _tracking, _emberCachedDecoratorPolyfill, _utils, _arrayUtils, _storeUtils, _filterUtilsNew, _fuzzysort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isGroupsContactCustomPropertyInfo = isGroupsContactCustomPropertyInfo;
  _exports.default = _exports.PropertyTypeLabels = void 0;

  var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const {
    moment
  } = window;
  let PropertyTypeLabels = {
    s: 'Text',
    n: 'Number',
    b: 'Yes / No',
    c: 'Currency',
    l: 'Select from List'
  };
  _exports.PropertyTypeLabels = PropertyTypeLabels;

  function isGroupsContactCustomPropertyInfo(info) {
    return info.l === 'Groups';
  }

  const ContactCustomPropertyTypeToFilterPropertyType = {
    's': 'string',
    'n': 'number',
    'b': 'boolean',
    'c': 'number',
    'l': 'list'
  };
  const ContactGiftHistoryCache = {
    generateCacheKey(operation, val) {
      return `${moment().format('YYYYMMDD')}-${val.val}-${val.valType}-${operation}-${val.from}-${val.to}`;
    },

    cache: {}
  };
  let ContactFuzzySort = (_class = class ContactFuzzySort {
    constructor(contact) {
      this.contact = contact;
    }

    get searchString() {
      let searchStrings = [this.contact.name];

      if (this.contact.namePartsDifferentThanDisplayName) {
        if ((0, _utils.isPresent)(this.contact.firstName)) {
          searchStrings.push(this.contact.firstName);
        }

        if ((0, _utils.isPresent)(this.contact.spouseFirstName)) {
          searchStrings.push(this.contact.spouseFirstName);
        }

        if ((0, _utils.isPresent)(this.contact.lastName)) {
          searchStrings.push(this.contact.lastName);
        }

        if ((0, _utils.isPresent)(this.contact.spouseLastName)) {
          searchStrings.push(this.contact.spouseLastName);
        }
      }

      if ((0, _utils.isPresent)(this.contact.city)) {
        searchStrings.push(this.contact.city);
      }

      for (let customProperty of ((_this$contact$custom = this.contact.custom) === null || _this$contact$custom === void 0 ? void 0 : _this$contact$custom.properties) || []) {
        var _this$contact$custom;

        if (customProperty.t == null || customProperty.t === 's') {
          searchStrings.push(customProperty.v);
        }
      }

      return searchStrings.join(' ');
    }

    get searchStringPrepared() {
      return _fuzzysort.default.prepare(this.searchString);
    }

    get emailPrepared() {
      var _this$contact$emails, _this$contact$emails$, _this$contact$emails$2;

      return _fuzzysort.default.prepare(((_this$contact$emails = this.contact.emails) === null || _this$contact$emails === void 0 ? void 0 : (_this$contact$emails$ = _this$contact$emails.data) === null || _this$contact$emails$ === void 0 ? void 0 : (_this$contact$emails$2 = _this$contact$emails$.map(x => x.v)) === null || _this$contact$emails$2 === void 0 ? void 0 : _this$contact$emails$2.join(' ')) || '');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "searchString", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "searchString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchStringPrepared", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "searchStringPrepared"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailPrepared", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "emailPrepared"), _class.prototype)), _class);
  let ContactsCache = (_class2 = class ContactsCache extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "campaignService", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _defineProperty(this, "nextPreviousContactIds", []);

      _initializerDefineProperty(this, "contacts", _descriptor5, this);

      _initializerDefineProperty(this, "contactsSortBy", _descriptor6, this);

      _initializerDefineProperty(this, "groupsCacheIndex", _descriptor7, this);

      _initializerDefineProperty(this, "customPropertiesCacheIndex", _descriptor8, this);

      _initializerDefineProperty(this, "showHiddenCustomPropertiesOnDetailPage", _descriptor9, this);

      _defineProperty(this, "_loadPromise", void 0);
    }

    get contactsById() {
      let index = {};

      for (let contact of this.contacts) {
        index[contact.id] = contact;
      }

      return index;
    }

    get sortedContacts() {
      return (0, _arrayUtils.sortByProperties)(this.contacts, this.contactsSortBy);
    } // Duct tape way to break the cache for `sortedGroups` and `groupContacts`
    // You have to manually increment `groupsCacheIndex` to
    // recalculate dependent computed properties


    get sortedGroups() {
      var _this$settings$fundSe, _this$settings$fundSe2;

      // access the groupsCacheIndex so the cached is cleared when it changes
      // @ts-ignore
      let _groupsCacheIndex = this.groupsCacheIndex;
      let groups = {};
      (_this$settings$fundSe = this.settings.fundSettings) === null || _this$settings$fundSe === void 0 ? void 0 : (_this$settings$fundSe2 = _this$settings$fundSe.defaultGroups) === null || _this$settings$fundSe2 === void 0 ? void 0 : _this$settings$fundSe2.forEach(group => {
        groups[group] = true;
      });
      this.contacts.forEach(contact => {
        var _contact$groups;

        (_contact$groups = contact.groups) === null || _contact$groups === void 0 ? void 0 : _contact$groups.forEach(group => {
          groups[group] = true;
        });
      });
      return Object.keys(groups).sort();
    }

    get groupContacts() {
      let groupContacts = {};
      this.sortedGroups.forEach(group => {
        groupContacts[group] = {
          contacts: []
        };
      });
      this.contacts.forEach(contact => {
        var _contact$groups2;

        (_contact$groups2 = contact.groups) === null || _contact$groups2 === void 0 ? void 0 : _contact$groups2.forEach(group => {
          groupContacts[group].contacts.push(contact);
        });
      });
      return groupContacts;
    }

    getById(contactId) {
      return this.contactsById[contactId];
    }

    get contactIdsAndGroupsOptions() {
      // access the groupsCacheIndex so the cached is cleared when it changes
      // @ts-ignore
      let _groupsCacheIndex = this.groupsCacheIndex;
      return this.sortedContacts.map(contact => {
        return {
          label: contact.name,
          val: `id:${contact.id}`,
          cityAndState: contact.cityAndState
        };
      }).concat(this.sortedGroups.map(group => {
        return {
          label: group,
          val: `group:${group}`,
          searchLabel: `group: ${group.toLowerCase()}`,
          isGroup: true
        };
      })).concat([{
        label: 'All Contacts',
        val: 'all-contacts'
      }]);
    }

    updateCustomPropertyInfo(newCustomPropertyInfo) {
      var _contactCustomInfo, _contactCustomInfo$pr;

      let existingInfoIndex = (_contactCustomInfo = this.settings.fundSettings.contactCustomInfo) === null || _contactCustomInfo === void 0 ? void 0 : (_contactCustomInfo$pr = _contactCustomInfo.properties) === null || _contactCustomInfo$pr === void 0 ? void 0 : _contactCustomInfo$pr.findIndex(x => x.l === newCustomPropertyInfo.l);

      if (existingInfoIndex == null) {
        existingInfoIndex = -1;
      }

      if (existingInfoIndex >= 0) {
        let existingInfo = this.settings.fundSettings.contactCustomInfo.properties[existingInfoIndex]; // only update the info if it's different

        if (JSON.stringify(existingInfo) !== JSON.stringify(newCustomPropertyInfo)) {
          this.settings.fundSettings.contactCustomInfo.properties[existingInfoIndex] = newCustomPropertyInfo; // break the cache

          this.customPropertiesCacheIndex += 1;
        }
      } else {
        if (this.settings.fundSettings.contactCustomInfo == null) {
          this.settings.fundSettings.contactCustomInfo = {
            properties: []
          };
        }

        this.settings.fundSettings.contactCustomInfo.properties.push(newCustomPropertyInfo); // break the cache

        this.customPropertiesCacheIndex += 1;
      }
    } // does NOT include the "Groups" custom property info


    get customProperties() {
      var _this$settings$fundSe3, _this$settings$fundSe4;

      // access the customPropertiesCacheIndex so the cached is cleared when it changes
      // @ts-ignore
      let _customPropertiesCacheIndex = this.customPropertiesCacheIndex;
      return ((_this$settings$fundSe3 = this.settings.fundSettings) === null || _this$settings$fundSe3 === void 0 ? void 0 : (_this$settings$fundSe4 = _this$settings$fundSe3.contactCustomInfo) === null || _this$settings$fundSe4 === void 0 ? void 0 : _this$settings$fundSe4.properties) || [];
    } // TODO: fix the spelling mistake on the function name


    get customPropertiesByLowecaseLabel() {
      let info = {};

      for (let x of this.customProperties) {
        info[x.l.toLowerCase()] = x;
      }

      return info;
    }

    customPropertyLabelIsReadonly(label) {
      var _this$customPropertie;

      return ((_this$customPropertie = this.customPropertiesByLowecaseLabel[label.toLowerCase()]) === null || _this$customPropertie === void 0 ? void 0 : _this$customPropertie.r) || false;
    }

    get groupsContactCustomPropertyInfo() {
      return {
        l: 'Groups',
        t: 'l',
        o: this.sortedGroups,
        s: {
          single_selection: false
        },
        isGroupsProperty: true
      };
    } // includes "Groups" custom property


    get sortedCustomPropertiesForDetailPage() {
      var _this$settings$contac, _this$settings$contac2;

      let customProperties = [this.groupsContactCustomPropertyInfo, ...this.customProperties];

      if ((((_this$settings$contac = this.settings.contactCustomPropertyUserSetting) === null || _this$settings$contac === void 0 ? void 0 : (_this$settings$contac2 = _this$settings$contac.detailPage) === null || _this$settings$contac2 === void 0 ? void 0 : _this$settings$contac2.length) || 0) > 0) {
        let genericCustomProperties = [...customProperties];
        customProperties = [];
        let customProperty;

        for (let x of this.settings.contactCustomPropertyUserSetting.detailPage) {
          if (customProperty = genericCustomProperties.find(y => y.l === x.l)) {
            customProperties.push(customProperty);
          }
        } // add any missing custom properties


        for (let x of genericCustomProperties) {
          if (!customProperties.includes(x)) {
            customProperties.push(x);
          }
        } // TODO: do we save the missing custom properties to `detailPage` settings here?
        // TODO: do we remove any detailPage custom properties that are no longer in `customProperties`?

      }

      return customProperties;
    }

    async updateCustomPropertyForDetailPageVisibility(label, visibility) {
      var _contactCustomPropert;

      let {
        contactCustomPropertyUserSetting
      } = this.settings;

      if (contactCustomPropertyUserSetting == null) {
        contactCustomPropertyUserSetting = this.store.createRecord('contact-custom-property-user-setting', {
          detailPage: []
        });
      }

      let updatedDetailPage = [];

      if (((_contactCustomPropert = contactCustomPropertyUserSetting.detailPage) === null || _contactCustomPropert === void 0 ? void 0 : _contactCustomPropert.length) === 0) {
        updatedDetailPage = this.defaultContactCustomPropertyUserSettingForDetailPage;
      } else if (contactCustomPropertyUserSetting.detailPage.map(x => x.l).join(',') !== this.sortedCustomPropertiesForDetailPage.map(x => x.l).join(',')) {
        // if detailPage doesn't equal the current sorted custom properties, update it
        updatedDetailPage = this.sortedCustomPropertiesForDetailPage.map(x => {
          return contactCustomPropertyUserSetting.detailPage.find(y => y.l === x.l) || {
            l: x.l
          };
        });
      } else {
        updatedDetailPage = [...contactCustomPropertyUserSetting.detailPage];
      } // update the visibility


      let visibilityChanged = false;
      let item = updatedDetailPage.find(x => x.l === label) || {
        l: label
      };

      if (visibility === 'show') {
        if (item.show !== true) {
          visibilityChanged = true;
          item.show = true;

          if (item.hide) {
            delete item.hide;
          }
        }
      } else if (visibility === 'hide') {
        if (item.hide !== true) {
          visibilityChanged = true;
          item.hide = true;

          if (item.show) {
            delete item.show;
          }
        }
      } else {
        if (item.show || item.hide) {
          visibilityChanged = true;

          if (item.show) {
            delete item.show;
          }

          if (item.hide) {
            delete item.hide;
          }
        }
      }

      if (!visibilityChanged) {
        return;
      } // update the order of properties


      let updatedDetailPageWithoutItem = updatedDetailPage.filter(x => x !== item);
      let alwaysShowItems = updatedDetailPageWithoutItem.filter(x => x.show === true);
      let hideIfBlankItems = updatedDetailPageWithoutItem.filter(x => x.show !== true && x.hide !== true);
      let alwaysHideItems = updatedDetailPageWithoutItem.filter(x => x.hide === true); // add the changed property to the end of its new visibility section

      if (item.show) {
        alwaysShowItems.push(item);
      } else if (item.hide) {
        alwaysHideItems.push(item);
      } else {
        hideIfBlankItems.push(item);
      }

      let sortedDetailPage = [...alwaysShowItems, ...hideIfBlankItems, ...alwaysHideItems]; // no longer show hidden properties if there are no hidden properties

      if (hideIfBlankItems.length === 0 && alwaysHideItems.length === 0 && this.showHiddenCustomPropertiesOnDetailPage) {
        this.showHiddenCustomPropertiesOnDetailPage = false;
      }

      contactCustomPropertyUserSetting.detailPage = sortedDetailPage;
      this.settings.contactCustomPropertyUserSetting = contactCustomPropertyUserSetting;
      await contactCustomPropertyUserSetting.save();
    }

    get customPropertyUserSettingForDetailPage() {
      var _this$settings$contac3;

      let settings = {};
      let detailPage = (_this$settings$contac3 = this.settings.contactCustomPropertyUserSetting) === null || _this$settings$contac3 === void 0 ? void 0 : _this$settings$contac3.detailPage;

      if (detailPage == null || detailPage.length === 0) {
        detailPage = this.defaultContactCustomPropertyUserSettingForDetailPage;
      }

      for (let x of detailPage) {
        settings[x.l] = x;
      }

      return settings;
    }

    get defaultContactCustomPropertyUserSettingForDetailPage() {
      return [this.groupsContactCustomPropertyInfo, ...this.customProperties].map(x => {
        let item = {
          l: x.l
        }; // Groups and Status default to always show

        if (item.l === 'Groups' || item.l === 'Status') {
          item.show = true;
        }

        return item;
      });
    }

    get availablePropertyFiltersForContacts() {
      let filterInfos = [];
      filterInfos.push({
        propertyName: 'contactNames',
        label: 'Name Fields',
        propertyType: 'string',
        availableOperations: ['stringContains'],
        custom: {
          filter: args => {
            var _args$val, _args$val$toLowerCase;

            let searchWords = ((_args$val = args.val) === null || _args$val === void 0 ? void 0 : (_args$val$toLowerCase = _args$val.toLowerCase()) === null || _args$val$toLowerCase === void 0 ? void 0 : _args$val$toLowerCase.split(/\s+/)) || [];
            let matchInfo = args.contacts.map(contact => {
              return {
                contact,
                matches: searchWords.map(word => contact.allNamesLowerCase.indexOf(word)).filter(x => x >= 0).sort()
              };
            }).filter(info => {
              return info.matches.length > 0;
            });

            if (searchWords.length > 1 && matchInfo.some(x => x.matches.length > 1)) {
              return matchInfo.filter(x => x.matches.length > 1).map(x => x.contact);
            } else {
              return matchInfo.map(x => x.contact);
            }
          },
          includeOnAnyReport: true
        }
      });

      if (this.sortedGroups.length > 0) {
        filterInfos.push({
          propertyName: 'contactGroups',
          label: 'Groups',
          propertyType: 'list',
          listOptions: this.sortedGroups,
          custom: {
            filter: args => {
              let filteredVals = args.val;

              if (args.operation === 'any') {
                return args.contacts.filter(contact => {
                  var _contact$groups3;

                  return (_contact$groups3 = contact.groups) === null || _contact$groups3 === void 0 ? void 0 : _contact$groups3.some(x => filteredVals.includes(x));
                });
              } else if (args.operation === 'none') {
                return args.contacts.filter(contact => {
                  var _contact$groups4;

                  return !((_contact$groups4 = contact.groups) !== null && _contact$groups4 !== void 0 && _contact$groups4.some(x => filteredVals.includes(x)));
                });
              } else if (args.operation === 'all') {
                return args.contacts.filter(contact => {
                  return filteredVals.every(x => {
                    var _contact$groups5;

                    return (_contact$groups5 = contact.groups) === null || _contact$groups5 === void 0 ? void 0 : _contact$groups5.includes(x);
                  });
                });
              } else if (args.operation === 'absent') {
                return args.contacts.filter(contact => {
                  return !contact.hasGroups;
                });
              } else if (args.operation === 'present') {
                return args.contacts.filter(contact => {
                  return contact.hasGroups;
                });
              } else {
                // it shouldn't ever get here
                return args.contacts;
              }
            },
            includeOnAnyReport: true
          }
        });
      }

      filterInfos.push({
        propertyName: 'contactGiftHistory',
        label: 'Has Given',
        propertyType: 'giftHistory',
        custom: {
          preFilterQuery: async filter => {
            let filterVal = filter.val;
            let cacheKey = ContactGiftHistoryCache.generateCacheKey(filter.operation, filterVal);

            if (ContactGiftHistoryCache.cache[cacheKey] == null) {
              let record = (await this.store.query('contact-filter', {
                filter: {
                  gift_history: {
                    val: filterVal.val,
                    val_type: filterVal.valType,
                    operation: filter.operation,
                    from: filterVal.from,
                    to: filterVal.to
                  }
                }
              })).toArray()[0];
              ContactGiftHistoryCache.cache[cacheKey] = {};

              for (let contactId of record.contactIds) {
                ContactGiftHistoryCache.cache[cacheKey][contactId] = true;
              }
            }
          },
          filter: args => {
            let filterVal = args.val;
            let cacheKey = ContactGiftHistoryCache.generateCacheKey(args.operation, filterVal);
            return args.contacts.filter(contact => ContactGiftHistoryCache.cache[cacheKey][contact.id]);
          },
          includeOnAnyReport: true
        }
      });

      if (this.campaignService.campaignsEnabled && this.campaignService.campaigns.length > 0) {
        // have to call is so the cache is correctly broken since it's not used
        // until the filter is actually used
        let breakTheCache = this.campaignService.campaignContacts;
        let {
          sortedCampaigns
        } = this.campaignService;
        filterInfos.push({
          propertyName: 'campaignIds',
          label: 'Campaigns',
          propertyType: 'list',
          listOptions: sortedCampaigns.map(x => {
            return {
              val: x.id,
              label: x.label
            };
          }),
          custom: {
            filter: args => {
              const campaignIds = args.val;

              switch (args.operation) {
                case 'any':
                  return args.contacts.filter(contact => {
                    var _this$campaignService;

                    return (_this$campaignService = this.campaignService.campaignContactsByContactId[contact.id]) === null || _this$campaignService === void 0 ? void 0 : _this$campaignService.some(c => campaignIds.includes(c.campaignId));
                  });

                case 'all':
                  return args.contacts.filter(contact => {
                    let campaignContacts = this.campaignService.campaignContactsByContactId[contact.id];
                    return campaignContacts && campaignIds.every(id => campaignContacts.some(c => c.campaignId === id));
                  });

                case 'none':
                  // not in any
                  return args.contacts.filter(contact => {
                    let campaignContacts = this.campaignService.campaignContactsByContactId[contact.id];
                    return campaignContacts == null || !campaignIds.some(id => campaignContacts.some(c => c.campaignId === id));
                  });

                case 'present':
                  return args.contacts.filter(contact => {
                    return (this.campaignService.campaignContactsByContactId[contact.id] || []).length > 0;
                  });

                case 'absent':
                  return args.contacts.filter(contact => {
                    return (this.campaignService.campaignContactsByContactId[contact.id] || []).length === 0;
                  });

                default:
                  return args.contacts;
              }
            },
            includeOnAnyReport: true
          }
        });
      }

      for (let customProperty of this.customProperties) {
        var _customProperty$s;

        let valForContact = contact => {
          var _contact$customProper;

          let val = (_contact$customProper = contact.customPropertiesByLabel[customProperty.l]) === null || _contact$customProper === void 0 ? void 0 : _contact$customProper.v;

          if (val && customProperty.t === 's') {
            val = val.toLowerCase();
          }

          return val;
        };

        filterInfos.push({
          propertyName: `contact_custom_${customProperty.l}`,
          label: customProperty.l,
          propertyType: ContactCustomPropertyTypeToFilterPropertyType[customProperty.t || 's'],
          listOptions: customProperty.o,
          listIsSingleSelection: ((_customProperty$s = customProperty.s) === null || _customProperty$s === void 0 ? void 0 : _customProperty$s.single_selection) || false,
          custom: {
            filter: args => {
              if (customProperty.t === 'l') {
                let filteredVals = args.val;

                if (args.operation === 'any') {
                  return args.contacts.filter(contact => {
                    let contactVal = valForContact(contact);
                    return contactVal && filteredVals.some(x => contactVal[x] === true);
                  });
                } else if (args.operation === 'none') {
                  return args.contacts.filter(contact => {
                    let contactVal = valForContact(contact);
                    return contactVal == null || !filteredVals.some(x => contactVal[x] === true);
                  });
                } else if (args.operation === 'absent') {
                  return args.contacts.filter(contact => {
                    return _filterUtilsNew.OperationFilters.absent(valForContact(contact));
                  });
                } else if (args.operation === 'present') {
                  return args.contacts.filter(contact => {
                    return _filterUtilsNew.OperationFilters.present(valForContact(contact));
                  });
                } else {
                  // (args.operation === 'all')
                  return args.contacts.filter(contact => {
                    let contactVal = valForContact(contact);
                    return contactVal && filteredVals.every(x => contactVal[x] === true);
                  });
                }
              } else if (args.operation === '=') {
                if (customProperty.t === 's') {
                  let filterValLowerCase = args.val.toLowerCase();
                  return args.contacts.filter(contact => {
                    return _filterUtilsNew.OperationFilters.stringEquals(filterValLowerCase, valForContact(contact));
                  });
                } else {
                  let filterVal = args.val;

                  if (customProperty.t === 'n' || customProperty.t === 'c') {
                    filterVal = Number(filterVal);
                  }

                  return args.contacts.filter(contact => {
                    return valForContact(contact) === filterVal;
                  });
                }
              } else if (args.operation === 'absent') {
                return args.contacts.filter(contact => {
                  return _filterUtilsNew.OperationFilters.absent(valForContact(contact));
                });
              } else if (args.operation === 'present') {
                return args.contacts.filter(contact => {
                  return _filterUtilsNew.OperationFilters.present(valForContact(contact));
                });
              } else if (args.operation === 'stringContains') {
                // only works for strings
                let filterValLowerCase = args.val.toLowerCase();
                return args.contacts.filter(contact => {
                  return _filterUtilsNew.OperationFilters.stringContains(filterValLowerCase, valForContact(contact));
                });
              } else if (args.operation === '>') {
                let filterVal = Number(args.val);
                return args.contacts.filter(contact => {
                  return valForContact(contact) > filterVal;
                });
              } else if (args.operation === '>=') {
                let filterVal = Number(args.val);
                return args.contacts.filter(contact => {
                  return valForContact(contact) >= filterVal;
                });
              } else if (args.operation === '<') {
                let filterVal = Number(args.val);
                return args.contacts.filter(contact => {
                  return valForContact(contact) < filterVal;
                });
              } else if (args.operation === '<=') {
                let filterVal = Number(args.val);
                return args.contacts.filter(contact => {
                  return valForContact(contact) <= filterVal;
                });
              } else if (args.operation === '!=') {
                let filterVal = args.val;

                if (customProperty.t === 'n' || customProperty.t === 'c') {
                  filterVal = Number(filterVal);
                }

                return args.contacts.filter(contact => {
                  return valForContact(contact) != filterVal;
                });
              } else {
                return args.contacts;
              }
            },
            includeOnAnyReport: true
          }
        });
      }

      filterInfos.push({
        propertyName: 'contactPhones',
        label: 'Phone',
        propertyType: 'string',
        availableOperations: ['=', 'stringContains', 'present', 'absent'],
        custom: {
          filter: args => {
            if (args.operation === 'present') {
              return args.contacts.filter(contact => {
                return contact.hasPhones;
              });
            } else if (args.operation === 'absent') {
              return args.contacts.filter(contact => {
                return !contact.hasPhones;
              });
            } else if (args.operation === 'stringContains') {
              let valDigitsOnly = args.val.replace(/\D/g, '');
              return args.contacts.filter(contact => {
                if (contact.hasPhones) {
                  return contact.phones.data.some(x => x.v.replace(/\D/g, '').includes(valDigitsOnly));
                } else {
                  return false;
                }
              });
            } else {
              // args.operation === '='
              let valDigitsOnly = args.val.replace(/\D/g, '');
              return args.contacts.filter(contact => {
                if (contact.hasPhones) {
                  return contact.phones.data.some(x => x.v.replace(/\D/g, '') == valDigitsOnly);
                } else {
                  return false;
                }
              });
            }
          },
          includeOnAnyReport: false
        }
      });
      filterInfos.push({
        propertyName: 'contactEmails',
        label: 'Email',
        propertyType: 'string',
        availableOperations: ['=', 'stringContains', 'present', 'absent'],
        custom: {
          filter: args => {
            if (args.operation === 'present') {
              return args.contacts.filter(contact => {
                return contact.hasEmails;
              });
            } else if (args.operation === 'absent') {
              return args.contacts.filter(contact => {
                return !contact.hasEmails;
              });
            } else if (args.operation === 'stringContains') {
              let valLowerCase = args.val.toLowerCase();
              return args.contacts.filter(contact => {
                if (contact.hasEmails) {
                  return contact.emails.data.some(x => x.v.toLowerCase().includes(valLowerCase));
                } else {
                  return false;
                }
              });
            } else {
              // args.operation === '='
              let valLowerCase = args.val.toLowerCase();
              return args.contacts.filter(contact => {
                if (contact.hasEmails) {
                  return contact.emails.data.some(x => x.v.toLowerCase() == valLowerCase);
                } else {
                  return false;
                }
              });
            }
          },
          includeOnAnyReport: false
        }
      });
      filterInfos.push({
        propertyName: 'contactCity',
        label: 'City',
        propertyType: 'string',
        availableOperations: ['=', 'stringContains'],
        custom: {
          filter: args => {
            let valLowerCase = args.val.toLowerCase();

            if (args.operation === '=') {
              return args.contacts.filter(contact => {
                var _contact$city;

                return ((_contact$city = contact.city) === null || _contact$city === void 0 ? void 0 : _contact$city.toLowerCase()) == valLowerCase;
              });
            } else {
              return args.contacts.filter(contact => {
                var _contact$city2, _contact$city2$toLowe;

                return (_contact$city2 = contact.city) === null || _contact$city2 === void 0 ? void 0 : (_contact$city2$toLowe = _contact$city2.toLowerCase()) === null || _contact$city2$toLowe === void 0 ? void 0 : _contact$city2$toLowe.includes(valLowerCase);
              });
            }
          },
          includeOnAnyReport: false
        }
      });
      filterInfos.push({
        propertyName: 'contactState',
        label: 'State',
        propertyType: 'string',
        availableOperations: ['=', 'stringContains'],
        custom: {
          filter: args => {
            let valLowerCase = args.val.toLowerCase();

            if (args.operation === '=') {
              return args.contacts.filter(contact => {
                var _contact$state;

                return ((_contact$state = contact.state) === null || _contact$state === void 0 ? void 0 : _contact$state.toLowerCase()) == valLowerCase;
              });
            } else {
              return args.contacts.filter(contact => {
                var _contact$state2, _contact$state2$toLow;

                return (_contact$state2 = contact.state) === null || _contact$state2 === void 0 ? void 0 : (_contact$state2$toLow = _contact$state2.toLowerCase()) === null || _contact$state2$toLow === void 0 ? void 0 : _contact$state2$toLow.includes(valLowerCase);
              });
            }
          },
          includeOnAnyReport: false
        }
      });
      filterInfos.push({
        propertyName: 'contact_show_hidden',
        label: 'Show Hidden',
        propertyType: 'no-operation',
        custom: {
          filter: args => {
            // don't filter anything here
            return args.contacts;
          },
          includeOnAnyReport: false
        }
      });
      return filterInfos;
    }

    loadContacts({
      forceLoad
    } = {}) {
      var _this$fundsService$se;

      if (forceLoad) {
        this._loadPromise = undefined;
      }

      if (this._loadPromise) {
        return this._loadPromise;
      }

      return this._loadPromise = (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'contact',
        pageSize: 250,
        // these queryOptions don't affect the filter but it includes the
        // fund id in the error report if this request times out so we know
        // what fund id it's having trouble loading contacts for
        queryOptions: {
          fund_id: (_this$fundsService$se = this.fundsService.selectedFund) === null || _this$fundsService$se === void 0 ? void 0 : _this$fundsService$se.id
        }
      }).then(contacts => {
        this.contacts = contacts;
        this.groupsCacheIndex += 1;
        this.nextPreviousContactIds = this.sortedContacts.map(x => x.id);
        return contacts;
      });
    }

    async reloadSpecificContacts(contactIds) {
      if (contactIds.length > 250) {
        return this.loadContacts({
          forceLoad: true
        });
      } else {
        let reloadedContacts = (0, _storeUtils.queryWithPages)({
          store: this.store,
          modelName: 'contact',
          pageSize: 250,
          queryOptions: {
            filter: {
              id: contactIds
            }
          }
        });
        this.contacts = this.contacts; // break the cache

        return reloadedContacts;
      }
    } // loads new contacts added or updated today


    async loadModifiedContacts() {
      var _this$fundsService$se2;

      let modifiedContacts = await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'contact',
        pageSize: 250,
        // these queryOptions don't affect the filter but it includes the
        // fund id in the error report if this request times out so we know
        // what fund id it's having trouble loading contacts for
        queryOptions: {
          fund_id: (_this$fundsService$se2 = this.fundsService.selectedFund) === null || _this$fundsService$se2 === void 0 ? void 0 : _this$fundsService$se2.id,
          filter: {
            updated_today: true
          }
        }
      }); // add the new contacts

      let newContacts = modifiedContacts.filter(x => this.contactsById[x.id] == null);
      this.contacts = [...this.contacts, ...newContacts];
      this.groupsCacheIndex += 1;
    }

    removeContactId(contactId) {
      this.contacts = this.contacts.filter(x => x.id !== contactId);
    } // Creates a contact and adds it to the contacts list
    // It does NOT save the contact though


    createContact(initialValues = {}) {
      // don't need to explicitly add the new record to `contacts` because it's already
      // a RecordArray from the store, so the store will do it for us
      return this.store.createRecord('contact', initialValues);
    }

    unload() {
      this._loadPromise = undefined;
      this.contacts = [];
    } // @cached
    // get indexByStartsWith(): ContactIndexByStartsWith {
    //   let startsWithIndex: ContactIndexByStartsWith = {};
    //   this.sortedContacts.forEach(contact => {
    //     let names = (contact.name || '').split(' ');
    //     names.forEach(nameWord => {
    //       if (nameWord.length > 1) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, nameWord);
    //       }
    //     });
    //     if (contact.namePartsDifferentThanDisplayName) {
    //       if (isPresent(contact.firstName)) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, contact.firstName!);
    //       }
    //       if (isPresent(contact.spouseFirstName)) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, contact.spouseFirstName!);
    //       }
    //       if (isPresent(contact.lastName)) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, contact.lastName!);
    //       }
    //       if (isPresent(contact.spouseLastName)) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, contact.spouseLastName!);
    //       }
    //     }
    //     let cityWords = (contact.city || '').split(' ');
    //     cityWords.forEach(cityWord => {
    //       if (cityWord.length > 1) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, cityWord);
    //       }
    //     });
    //     contact.emails?.data?.forEach(item => {
    //       if (item.v.length > 1) {
    //         this.addContactToStartsWithIndex(startsWithIndex, contact, item.v);
    //       }
    //     });
    //     contact.custom?.properties?.forEach(item => {
    //       if (item.t == null || item.t === 's') {
    //         let valueWords = item.v?.toString()?.split(' ') || [];
    //         valueWords.forEach((word:string) => {
    //           if (word.length > 1) {
    //             this.addContactToStartsWithIndex(startsWithIndex, contact, word);
    //           }
    //         });
    //       }
    //     });
    //   });
    //   return startsWithIndex;
    // }
    // addContactToStartsWithIndex(startsWithIndex: ContactIndexByStartsWith, contact: ContactModel, word: string) {
    //   let scrubbedWord = normalizeAccentCharacters(word).toLowerCase();
    //   let startsWith   = normalizeAccentCharacters(word).toLowerCase().slice(0, 2);
    //   startsWithIndex[startsWith] = (startsWithIndex[startsWith] || {});
    //   startsWithIndex[startsWith][scrubbedWord] = (startsWithIndex[startsWith][scrubbedWord] || []);
    //   startsWithIndex[startsWith][scrubbedWord].push(contact);
    // }


    get contactFuzzySorts() {
      return this.sortedContacts.map(contact => {
        return new ContactFuzzySort(contact);
      });
    }

    search(query, options = {}) {
      if ((0, _utils.isEmpty)(query)) {
        if (options.limit) {
          return this.sortedContacts.slice(0, options.limit);
        } else {
          return this.sortedContacts;
        }
      }

      if (options.threshold == null) {
        options.threshold = -5000;
      }

      let fuzzysortOptions = {
        threshold: options.threshold,
        key: 'searchStringPrepared'
      };

      if (query.includes('@')) {
        fuzzysortOptions.key = 'emailPrepared';
      }

      if (options !== null && options !== void 0 && options.limit) {
        fuzzysortOptions.limit = options.limit;
      }

      return _fuzzysort.default.go(query, this.contactFuzzySorts, fuzzysortOptions).map(fuzzyResult => {
        return fuzzyResult.obj.contact;
      }); // this is the old search method that uses the indexByStartsWith
      // we migrated to fuzzysort to get better search results
      // but are keeping this here to confirm the new search results
      // are better than the old search results
      // let queryWords = normalizeAccentCharacters(query || '').toLowerCase().split(' ').filter(item => {
      //   return item !== '';
      // });
      // if (queryWords.length === 0) {
      //   return this.sortedContacts;
      // }
      // let indexByStartsWith = this.indexByStartsWith;
      // let matchedContactsById: any = {};
      // let contactIdsMatchCount: any = {};
      // queryWords.forEach(queryWord => {
      //   if (queryWord.length > 1) {
      //     let startsWithMatches = indexByStartsWith[queryWord.slice(0, 2)];
      //     if (startsWithMatches) {
      //       Object.keys(startsWithMatches).forEach(indexWord => {
      //         if (indexWord.indexOf(queryWord) === 0) {
      //           startsWithMatches[indexWord].forEach(contact => {
      //             matchedContactsById[contact.id] = contact;
      //             contactIdsMatchCount[contact.id] = (contactIdsMatchCount[contact.id] || 0) + 1;
      //           });
      //         }
      //       });
      //     }
      //   }
      // });
      // let highestMatchCount = 0;
      // let contactsByMatchCount: any = {};
      // Object.keys(contactIdsMatchCount).forEach(contactId => {
      //   let matchCount = contactIdsMatchCount[contactId];
      //   if (matchCount > highestMatchCount) { highestMatchCount = matchCount; }
      //   if (contactsByMatchCount[matchCount] == null) {
      //     contactsByMatchCount[matchCount] = [];
      //   }
      //   contactsByMatchCount[matchCount].push(matchedContactsById[contactId]);
      // });
      // let results: ContactModel[] = [];
      // for (let i = highestMatchCount; i > 0; i--) {
      //   if (contactsByMatchCount[i]) {
      //     results.pushObjects(contactsByMatchCount[i]);
      //   }
      // }
      // return results;
    }

    async filter(appliedFilters, {
      excludeHiddenUnlessFiltered
    } = {}) {
      if (appliedFilters == null || appliedFilters.length === 0) {
        if (excludeHiddenUnlessFiltered) {
          return this.sortedContacts.filter(x => x.active);
        } else {
          return this.sortedContacts;
        }
      }

      let showHidden = false;
      let filteredContacts = this.sortedContacts.slice();

      for (let filter of appliedFilters) {
        if (filter.propertyName === 'contact_show_hidden') {
          showHidden = true;
        }

        let filterInfo = this.availablePropertyFiltersForContacts.find(x => x.propertyName === filter.propertyName);

        if (filterInfo.custom.preFilterQuery) {
          await filterInfo.custom.preFilterQuery(filter);
        }

        filteredContacts = filterInfo.custom.filter({
          operation: filter.operation,
          val: filter.val,
          contacts: filteredContacts
        });
      }

      if (excludeHiddenUnlessFiltered && showHidden === false) {
        filteredContacts = filteredContacts.filter(x => x.active);
      }

      return filteredContacts;
    }

    async reloadContact(contactId) {
      let contact = this.contactsById[contactId];
      return contact.reload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "contacts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "contactsById", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "contactsById"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "contactsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['nameLowerCase:asc'];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "sortedContacts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedContacts"), _class2.prototype), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "groupsCacheIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "customPropertiesCacheIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "sortedGroups", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedGroups"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "groupContacts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "groupContacts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "customProperties", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "customProperties"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "customPropertiesByLowecaseLabel", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "customPropertiesByLowecaseLabel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sortedCustomPropertiesForDetailPage", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedCustomPropertiesForDetailPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "customPropertyUserSettingForDetailPage", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "customPropertyUserSettingForDetailPage"), _class2.prototype), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "showHiddenCustomPropertiesOnDetailPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "availablePropertyFiltersForContacts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "availablePropertyFiltersForContacts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "contactFuzzySorts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class2.prototype, "contactFuzzySorts"), _class2.prototype)), _class2);
  _exports.default = ContactsCache;
});