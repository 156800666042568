define("donor-elf-web/services/contacts-export", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsExportService = (_class = class ContactsExportService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "campaignService", _descriptor, this);

      _initializerDefineProperty(this, "journalService", _descriptor2, this);
    }

    // used when no additional columns are needed
    // otherwise, use addContactsHeaderColumns() and addContactInfoToRow() below
    async perform(contacts) {
      let headerRow = [];
      let headerInfo = await this.addContactsHeaderColumns(headerRow, contacts);
      let rows = [headerRow];

      for (let contact of contacts) {
        let row = [];
        await this.addContactInfoToRow(row, contact, headerInfo);
        rows.push(row);
      }

      return rows;
    }
    /*
      addContactsHeaderColumns() and addContactInfoToRow() work together
       Example code:
      let rows = [['Date', 'Amount']];
      let headerInfo = this.contactsExport.addContactsHeaderColumns(rows[0], get(contactsCache, 'contacts'));
      for (let line of lineItems)
        let row = [line.transDate, line.amount];
        let contact = contactsCache.getById(line.contactId);
        this.contactsExport.addContactInfoToRow(row, contact, headerInfo);
        rows.push(row);
      });
    */


    async addContactsHeaderColumns(headerRow, contacts) {
      await this.campaignService.load();
      await this.journalService.load();
      let i = 0;
      let headerInfo = this.getHeaderInfo(contacts);

      for (let x of headerInfo.regularColumnMapping) {
        headerRow.push(x.column);
      }

      for (i = 0; i < headerInfo.maxPhonesLength; i++) {
        headerRow.push(`Phone ${i + 1}`);
      }

      for (i = 0; i < headerInfo.maxEmailsLength; i++) {
        headerRow.push(`Email ${i + 1}`);
      }

      for (let label of headerInfo.customPropertyLabels) {
        headerRow.push(label);
      }

      ;

      for (let group of headerInfo.groupNames) {
        headerRow.push(`Group: ${group}`);
      }

      ;

      for (let journalInfo of headerInfo.journalInfos) {
        headerRow.push(`Journal: ${journalInfo.journal.label}`);
      }

      for (let campaign of headerInfo.campaigns) {
        headerRow.push(`Campaign: ${campaign.label}`);
      }

      return headerInfo;
    } // making this async just in case we need it later then we don't have to
    // update the calling code


    async addContactInfoToRow(row, contact, headerInfo) {
      var _contact$phones, _contact$emails;

      let i = 0;

      if (contact == null) {
        contact = {};
      }

      for (let x of headerInfo.regularColumnMapping) {
        row.push(contact[x.property]);
      }

      ;
      let phonesData = ((_contact$phones = contact.phones) === null || _contact$phones === void 0 ? void 0 : _contact$phones.data) || [];

      for (i = 0; i < headerInfo.maxPhonesLength; i++) {
        if (phonesData.length > i) {
          var _phonesData$i;

          row.push((_phonesData$i = phonesData[i]) === null || _phonesData$i === void 0 ? void 0 : _phonesData$i['v']);
        } else {
          row.push(null);
        }
      }

      let emailsData = ((_contact$emails = contact.emails) === null || _contact$emails === void 0 ? void 0 : _contact$emails.data) || [];

      for (i = 0; i < headerInfo.maxEmailsLength; i++) {
        if (emailsData.length > i) {
          var _emailsData$i;

          row.push((_emailsData$i = emailsData[i]) === null || _emailsData$i === void 0 ? void 0 : _emailsData$i['v']);
        } else {
          row.push(null);
        }
      }

      for (let label of headerInfo.customPropertyLabels) {
        var _contact$customProper;

        let prop = (_contact$customProper = contact.customPropertiesByLabel) === null || _contact$customProper === void 0 ? void 0 : _contact$customProper[label];

        if (prop) {
          if (prop.t === 'l') {
            row.push(Object.keys(prop.v).join(' || '));
          } else {
            row.push(prop.v);
          }
        } else {
          row.push(null);
        }
      }

      ;

      for (let group of headerInfo.groupNames) {
        if ((contact.groups || []).indexOf(group) >= 0) {
          row.push('X');
        } else {
          row.push(null);
        }
      }

      ;

      if (headerInfo.journalInfos.length > 0) {
        let contactJournalInfos = this.journalService.journalInfosByContactId[contact.id] || [];

        for (let journalInfo of headerInfo.journalInfos) {
          if (contactJournalInfos.includes(journalInfo)) {
            row.push('X');
          } else {
            row.push(null);
          }
        }
      }

      if (headerInfo.campaigns.length > 0) {
        let campaignContacts = this.campaignService.campaignContactsByContactId[contact.id] || [];

        for (let campaign of headerInfo.campaigns) {
          if (campaignContacts.some(c => c.campaignId === campaign.id)) {
            row.push('X');
          } else {
            row.push(null);
          }
        }
      }
    }

    getHeaderInfo(contacts) {
      let maxPhonesLength = 0;
      let maxEmailsLength = 0;
      let customPropertyMapping = {};
      let groupMapping = {};
      let journalInfos = this.journalService.sortedJournalInfos;
      let campaigns = this.campaignService.campaignsEnabled ? this.campaignService.sortedCampaigns : [];

      for (let contact of contacts) {
        var _contact$phones2, _contact$phones2$data, _contact$emails2, _contact$emails2$data;

        let phonesLength = ((_contact$phones2 = contact.phones) === null || _contact$phones2 === void 0 ? void 0 : (_contact$phones2$data = _contact$phones2.data) === null || _contact$phones2$data === void 0 ? void 0 : _contact$phones2$data.length) || 0;

        if (phonesLength > maxPhonesLength) {
          maxPhonesLength = phonesLength;
        }

        let emailsLength = ((_contact$emails2 = contact.emails) === null || _contact$emails2 === void 0 ? void 0 : (_contact$emails2$data = _contact$emails2.data) === null || _contact$emails2$data === void 0 ? void 0 : _contact$emails2$data.length) || 0;

        if (emailsLength > maxEmailsLength) {
          maxEmailsLength = emailsLength;
        }

        for (let prop of ((_contact$custom = contact.custom) === null || _contact$custom === void 0 ? void 0 : _contact$custom.properties) || []) {
          var _contact$custom;

          customPropertyMapping[prop.l] = true;
        }

        ;

        for (let group of contact.groups || []) {
          groupMapping[group] = true;
        }

        ;
      }

      ;
      return {
        maxPhonesLength,
        maxEmailsLength,
        regularColumnMapping,
        customPropertyLabels: Object.keys(customPropertyMapping).sort(),
        groupNames: Object.keys(groupMapping).sort(),
        journalInfos,
        campaigns
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ContactsExportService;
  const regularColumnMapping = [{
    column: 'Display Name',
    property: 'name'
  }, {
    column: 'Mailing Name',
    property: 'mailingName'
  }, {
    column: 'Greeting',
    property: 'greeting'
  }, {
    column: 'First Name',
    property: 'firstName'
  }, {
    column: 'Last Name',
    property: 'lastName'
  }, {
    column: 'Spouse First Name',
    property: 'spouseFirstName'
  }, {
    column: 'Spouse Last Name',
    property: 'spouseLastName'
  }, {
    column: 'Address Line 1',
    property: 'address1'
  }, {
    column: 'Address Line 2',
    property: 'address2'
  }, {
    column: 'City',
    property: 'city'
  }, {
    column: 'State',
    property: 'state'
  }, {
    column: 'Zip',
    property: 'zip'
  }, {
    column: 'Country',
    property: 'country'
  }, {
    column: 'Deceased',
    property: 'deceased'
  }, {
    column: 'Spouse is Deceased',
    property: 'spouseDeceased'
  }, {
    column: 'Hidden',
    property: 'hidden'
  }];
});