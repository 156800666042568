define("donor-elf-web/controllers/funds/fund/pledges/index", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "donor-elf-web/lib/object-utils", "file-saver", "donor-elf-web/lib/pledge-utils", "donor-elf-web/lib/number-utils", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/filter-utils-new", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _object, _utils, _service, _objectUtils, _fileSaver, _pledgeUtils, _numberUtils, _tracking, _emberCachedDecoratorPolyfill, _filterUtilsNew, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Papa,
    moment
  } = window;
  let PledgesIndexController = (_class = class PledgesIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "campaignService", _descriptor, this);

      _initializerDefineProperty(this, "contactsCache", _descriptor2, this);

      _initializerDefineProperty(this, "contactsExport", _descriptor3, this);

      _initializerDefineProperty(this, "formatDateService", _descriptor4, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor5, this);

      _initializerDefineProperty(this, "settings", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      _defineProperty(this, "queryParams", ['filters']);

      _initializerDefineProperty(this, "filters", _descriptor8, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor9, this);

      _initializerDefineProperty(this, "loading", _descriptor10, this);

      _initializerDefineProperty(this, "monthlyGoal", _descriptor11, this);

      _initializerDefineProperty(this, "sortByProperty", _descriptor12, this);

      _initializerDefineProperty(this, "sortByDirection", _descriptor13, this);

      _initializerDefineProperty(this, "itemsAll", _descriptor14, this);

      _initializerDefineProperty(this, "itemsNoGifts", _descriptor15, this);

      _initializerDefineProperty(this, "itemsOverdue", _descriptor16, this);

      _initializerDefineProperty(this, "itemsGiving", _descriptor17, this);

      _initializerDefineProperty(this, "itemGroupCalculations", _descriptor18, this);
    }

    async load() {
      var _response$meta;

      this.loading = true;
      let {
        availablePropertyFilters,
        contactsCache
      } = this;
      let queryFilter = {
        includeMetaMonthlyGoal: true
      };

      for (let appliedFilter of this.appliedFilters || []) {
        let propertyFilter = availablePropertyFilters.find(x => x.propertyName === appliedFilter.propertyName);
        await propertyFilter.applyToQueryFilter(appliedFilter, queryFilter);
      }

      let response = await this.store.query('pledge', {
        filter: _objectUtils.default.decamelizeKeys(queryFilter)
      });

      if ((_response$meta = response.meta) !== null && _response$meta !== void 0 && _response$meta.monthly_goal) {
        this.monthlyGoal = Number(response.meta.monthly_goal);
      } else {
        this.monthlyGoal = undefined;
      }

      let itemsAll = [];

      for (let pledge of response.toArray()) {
        itemsAll.push({
          contact: contactsCache.contactsById[pledge.contactId],
          pledge
        });
      }

      this.itemsAll = itemsAll;
      this.setItemGroups();
      this.setItemGroupCalculations();
      this.loading = false;
    }

    get availablePropertyFilters() {
      let list = [];
      list.push({
        propertyName: 'startDate',
        label: 'Start Date',
        propertyType: 'date',
        availableOperations: ['>='],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.startDate = appliedFilter.val;
        }
      });
      list.push({
        propertyName: 'endDate',
        label: 'End Date',
        propertyType: 'date',
        availableOperations: ['<='],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.endDate = appliedFilter.val;
        }
      });
      list.push({
        propertyName: 'frequency',
        label: 'Frequency',
        propertyType: 'list',
        availableOperations: ['any'],
        listOptions: this.frequencyOptions,
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.frequency = appliedFilter.val;
        }
      });

      if (this.campaignService.campaignsEnabled) {
        list.push({
          propertyName: 'campaignId',
          label: 'Campaign',
          propertyType: 'list',
          availableOperations: ['any', 'absent', 'present'],
          listOptions: this.campaignService.sortedCampaigns.map(x => {
            return {
              val: x.id,
              label: x.name
            };
          }),
          applyToQueryFilter: async (appliedFilter, queryFilter) => {
            if (appliedFilter.operation === 'any') {
              queryFilter.campaignId = appliedFilter.val;
            } else if (appliedFilter.operation === 'absent') {
              queryFilter.campaignId = 'absent';
            } else if (appliedFilter.operation === 'present') {
              queryFilter.campaignId = 'present';
            }
          }
        });
      }

      list.push({
        propertyName: 'removeDuplicatesFromSameContact',
        label: 'Remove Duplicates from Same Contact',
        propertyType: 'no-operation',
        applyToQueryFilter: async (_appliedFilter, queryFilter) => {
          queryFilter.removeDuplicatesFromSameContact = true;
        }
      });
      list.push({
        propertyName: 'affectsGoal',
        label: 'Affects Goal',
        propertyType: 'boolean',
        availableOperations: ['='],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          if (appliedFilter.val) {
            queryFilter.affectsGoal = true;
          } else {
            queryFilter.affectsGoal = false;
          }
        }
      });
      let contactFilterInfos = this.contactsCache.availablePropertyFiltersForContacts.filter(x => x.custom.includeOnAnyReport && x.propertyName !== 'campaignIds');

      for (let filterInfo of contactFilterInfos) {
        list.push({
          propertyName: filterInfo.propertyName,
          label: `Contact ${filterInfo.label}`,
          propertyType: filterInfo.propertyType,
          availableOperations: filterInfo.availableOperations,
          listOptions: filterInfo.listOptions,
          listIsSingleSelection: filterInfo.listIsSingleSelection,
          applyToQueryFilter: async (appliedFilter, queryFilter) => {
            let contactIds = (await this.contactsCache.filter([appliedFilter])).map(x => x.id);

            if (contactIds.length === 0) {
              contactIds = ['-1']; // since no records match the contact filter, force the api to return 0 records
            }

            queryFilter.contactIds = (0, _filterUtilsNew.ContactIdsIntersection)(queryFilter.contactIds, contactIds);
          }
        });
      }

      return list;
    }

    setItemGroups() {
      let pledgesOverdueDate = this.settings.fundSettings.pledgesDelinquentDate;
      let itemsNoGifts = [];
      let itemsOverdue = [];
      let itemsGiving = [];

      for (let item of this.itemsAll) {
        let nextDate = item.pledge.nextExpectedDonationDate; // pledges with an end date coming up but no more payments are due will not
        // have a nextExpectedDonationDate

        if (!(0, _utils.isEmpty)(nextDate) && item.pledge.nextExpectedDonationDate < pledgesOverdueDate) {
          itemsOverdue.push(item);
        } else {
          if (item.pledge.hasGifts) {
            itemsGiving.push(item);
          } else {
            itemsNoGifts.push(item);
          }
        }
      }

      this.itemsNoGifts = itemsNoGifts;
      this.itemsOverdue = itemsOverdue;
      this.itemsGiving = itemsGiving;
    }

    setItemGroupCalculations() {
      let {
        monthlyGoal,
        isAnnualRollingGoal
      } = this;
      let calcs = {};

      for (let groupName of ['itemsAll', 'itemsNoGifts', 'itemsOverdue', 'itemsGiving']) {
        let items = this[groupName];
        ;

        if (items.length) {
          let monthlyAmount = items.map(item => item.pledge.monthlyAmount).reduce((prevVal, curVal) => _numberUtils.default.addCurrency(prevVal, curVal), 0);
          let percentOfGoal = monthlyGoal ? Math.round(monthlyAmount * 100 / monthlyGoal) : 0;

          if (isAnnualRollingGoal) {
            monthlyAmount *= 12;
          }

          calcs[groupName] = {
            monthlyAmount,
            percentOfGoal
          };
        } else {
          calcs[groupName] = {
            monthlyAmount: 0,
            percentOfGoal: 0
          };
        }
      }

      this.itemGroupCalculations = calcs;
    }

    templateDidInsert() {
      if ((0, _utils.isEmpty)(this.filters)) {
        this.filters = (0, _filterUtilsNew.serializeAppliedFilters)(this.defaultAppliedFilters);
      }

      this.deserializeFilters();
      this.load();
    }

    templateWillDestroy() {
      this.loading = true;
      this.filters = undefined;
      this.appliedFilters = undefined;
      this.itemsAll = [];
    }

    get actionsMenuItems() {
      return [{
        label: 'Download',
        svgJar: 'download',
        onClick: this.download
      }];
    }

    get defaultAppliedFilters() {
      return [{
        propertyName: 'startDate',
        operation: '>=',
        val: moment().startOf('month').format('YYYY-MM-DD')
      }, {
        propertyName: 'endDate',
        operation: '<=',
        val: moment().add(11, 'months').endOf('month').format('YYYY-MM-DD')
      }];
    }

    handleFiltersChanged(currentFilters) {
      this.appliedFilters = currentFilters;
      this.serializeFilters();
      this.load();
    }

    serializeFilters() {
      this.filters = (0, _filterUtilsNew.serializeAppliedFilters)(this.appliedFilters);
    }

    deserializeFilters() {
      this.appliedFilters = (0, _filterUtilsNew.deserializeAppliedFilters)(this.filters);
    }

    get frequencyOptions() {
      let options = [];

      for (let frequencyId in _pledgeUtils.pledgeFrequencyLabels) {
        if (_pledgeUtils.pledgeFrequencyLabels[frequencyId] !== 'Weekly') {
          options.push({
            val: frequencyId,
            label: _pledgeUtils.pledgeFrequencyLabels[frequencyId]
          });
        }
      }

      return options;
    }

    get excludeNoGiftsFromGoal() {
      return this.settings.fundSettings.lookingForwardExcludePledgesNoGifts;
    }

    get excludeOverdueFromGoal() {
      return this.settings.fundSettings.lookingForwardExcludePledgesDelinquent;
    }

    get isAnnualRollingGoal() {
      return this.settings.userSettings.showAnnualGoal;
    } // Don't show monthly summary for YTD goal types because it's confusing


    get showSummaryCard() {
      return !this.fundGoalService.hasYtdGoalType;
    }

    get sortBy() {
      return [`${this.sortByProperty}:${this.sortByDirection}`];
    }

    get sortedItemsNoGifts() {
      return (0, _arrayUtils.sortByProperties)(this.itemsNoGifts, this.sortBy);
    }

    get sortedItemsOverdue() {
      return (0, _arrayUtils.sortByProperties)(this.itemsOverdue, this.sortBy);
    }

    get sortedItemsGiving() {
      return (0, _arrayUtils.sortByProperties)(this.itemsGiving, this.sortBy);
    }

    get overduePledgesInDanger() {
      var _this$itemGroupCalcul, _this$itemGroupCalcul2;

      return this.excludeOverdueFromGoal && (((_this$itemGroupCalcul = this.itemGroupCalculations) === null || _this$itemGroupCalcul === void 0 ? void 0 : (_this$itemGroupCalcul2 = _this$itemGroupCalcul.itemsOverdue) === null || _this$itemGroupCalcul2 === void 0 ? void 0 : _this$itemGroupCalcul2.monthlyAmount) || 0) > 0;
    }

    get noGiftsPledgesInDanger() {
      var _this$itemGroupCalcul3, _this$itemGroupCalcul4;

      return this.excludeNoGiftsFromGoal && (((_this$itemGroupCalcul3 = this.itemGroupCalculations) === null || _this$itemGroupCalcul3 === void 0 ? void 0 : (_this$itemGroupCalcul4 = _this$itemGroupCalcul3.itemsNoGifts) === null || _this$itemGroupCalcul4 === void 0 ? void 0 : _this$itemGroupCalcul4.monthlyAmount) || 0) > 0;
    }

    handleSortBy(property) {
      let currentSortByProperty = this.sortByProperty;

      if (currentSortByProperty === property) {
        if (this.sortByDirection === 'asc') {
          this.sortByDirection = 'desc';
        } else {
          this.sortByDirection = 'asc';
        }
      } else {
        this.sortByProperty = property;
        this.sortByDirection = 'asc';
      }
    }

    async download() {
      let {
        formatDateService
      } = this;
      let rows = [];
      let headerRow = ['Group', 'Contact', 'Start Date', 'End Date', 'Frequency', 'Amount', 'Monthly Amount', 'Annual Amount', 'Last Gift', 'Next Gift', 'Notes', '---'];
      let headerInfo = await this.contactsExport.addContactsHeaderColumns(headerRow, this.contactsCache.contacts);
      rows.push(headerRow);

      let createRow = async item => {
        var _item$contact;

        let row = ['', (_item$contact = item.contact) === null || _item$contact === void 0 ? void 0 : _item$contact.name, formatDateService.formatForFile(item.pledge.startDate), formatDateService.formatForFile(item.pledge.endDate), item.pledge.frequencyLabel, item.pledge.amount, item.pledge.monthlyAmount, item.pledge.annualAmount, formatDateService.formatForFile(item.pledge.lastDonationDate), formatDateService.formatForFile(item.pledge.nextExpectedDonationDate), item.pledge.notes, ''];
        await this.contactsExport.addContactInfoToRow(row, item.contact, headerInfo);
        return row;
      };

      let createGroupHeaderRow = groupName => {
        let groupRow = [groupName];

        for (let i = 1; i < headerRow.length; i++) {
          groupRow.push('');
        }

        return groupRow;
      };

      rows.push(createGroupHeaderRow('Overdue Pledges'));

      for (let item of this.sortedItemsOverdue) {
        rows.push(await createRow(item));
      }

      rows.push([]);
      rows.push(createGroupHeaderRow('Pledges without Gifts'));

      for (let item of this.sortedItemsNoGifts) {
        rows.push(await createRow(item));
      }

      rows.push([]);
      rows.push(createGroupHeaderRow('Actively Giving Pledges'));

      for (let item of this.sortedItemsGiving) {
        rows.push(await createRow(item));
      }

      this.store.createRecord('contact-download').save();
      let csv = Papa.unparse(rows);
      var blob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
      });

      _fileSaver.default.saveAs(blob, 'pledges.csv', true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactsExport", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "appliedFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "monthlyGoal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "sortByProperty", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'contact.name';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sortByDirection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'asc';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "itemsAll", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "itemsNoGifts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "itemsOverdue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "itemsGiving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "itemGroupCalculations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "availablePropertyFilters", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "availablePropertyFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFiltersChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFiltersChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSortBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSortBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype)), _class);
  _exports.default = PledgesIndexController;
});