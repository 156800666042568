define("donor-elf-web/components/goal/balance-and-monthly-gifts-chart", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "donor-elf-web/lib/filter-utils-new"], function (_exports, _component, _object, _service, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment,
    Highcharts
  } = window;
  let GoalBalanceAndMonthlyGiftsChartComponent = (_class = class GoalBalanceAndMonthlyGiftsChartComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "isChartRendered", false);

      _defineProperty(this, "fontFamily", '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif');
    }

    get renderChart() {
      var _this$args$renderChar;

      return (_this$args$renderChar = this.args.renderChart) !== null && _this$args$renderChar !== void 0 ? _this$args$renderChar : true;
    } // has the chart been rendered


    get showBalance() {
      return !this.settings.orgSettings.incomeOnly;
    }

    get goalAmount() {
      return this.args.goalAmount || this.args.goal.goalAmount;
    }

    get monthlyGifts() {
      return this.args.goal.goalData.monthly_gifts;
    }

    get monthlyGiftsChartCategories() {
      return this.monthlyGifts.map(item => {
        return moment(item.month).format('MMM YY');
      });
    }

    get monthlyGiftsChartGiftsSeries() {
      let {
        monthlyGifts
      } = this;
      let series = [];

      for (let i = 0; i < monthlyGifts.length; i++) {
        let amount = monthlyGifts[i].amount;
        series.push({
          y: amount,
          month: monthlyGifts[i].month
        });
      }

      return series;
    }

    get monthlyGiftsChartGoalSeries() {
      if (this.args.goal.goalType === 'total') {
        return [];
      }

      let {
        goalAmount,
        monthlyGifts
      } = this;

      if (this.args.goal.goalType === 'annual') {
        goalAmount = goalAmount / 12;
      }

      let formattedGoalAmount = this.formatAmountService.format(goalAmount);
      return monthlyGifts.map(function () {
        return {
          y: goalAmount,
          formattedAmount: formattedGoalAmount
        };
      });
    }

    get monthlyGiftsChartSettings() {
      let self = this;
      return {
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.monthlyGiftsChartCategories,
          labels: {
            step: 3
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            marker: {
              enabled: true
            }
          }
        },
        series: [{
          type: 'column',
          name: 'Monthly Gifts',
          color: '#0b68a1',
          data: this.monthlyGiftsChartGiftsSeries,
          tooltip: {
            headerFormat: "<span style='font-size: 0.8rem'>{point.key}</span><br/>",
            pointFormatter: function () {
              let formattedAmount = self.formatAmountService.format(this.y);
              return `<span style='font-size=0.8rem'>${formattedAmount}</span>`;
            }
          },
          events: {
            click(event) {
              let month = event.point.month;
              self.router.transitionTo('funds.fund.transactions', {
                queryParams: {
                  filters: (0, _filterUtilsNew.serializeAppliedFilters)([{
                    propertyName: 'transDate',
                    operation: '><',
                    val: [month, moment(month).endOf('month').format('YYYY-MM-DD')]
                  }, {
                    propertyName: 'income'
                  }, {
                    propertyName: 'affectsGoal'
                  }])
                }
              });
            }

          }
        }, {
          type: 'line',
          name: 'Monthly Goal',
          color: '#f0ad4e',
          lineWidth: 3,
          marker: {
            enabled: false
          },
          data: this.monthlyGiftsChartGoalSeries,
          tooltip: {
            headerFormat: "<span style='font-size: 0.8rem'>{point.key}</span><br/>",
            pointFormatter: function () {
              return `<span style='font-size=0.8rem'>${this.formattedAmount}</span>`;
            }
          }
        }]
      };
    }

    handleRenderTheChart(el) {
      if (this.renderChart && !this.isChartRendered && !this.isDestroyed) {
        this.isChartRendered = true;
        Highcharts.chart(el.querySelector('.monthly-gifts-chart'), this.monthlyGiftsChartSettings);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleRenderTheChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRenderTheChart"), _class.prototype)), _class);
  _exports.default = GoalBalanceAndMonthlyGiftsChartComponent;
});