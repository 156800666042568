define("donor-elf-web/controllers/funds/fund/donors-by-time-frame", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/number-utils", "donor-elf-web/lib/object-utils", "ember-parachute", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validators/date-or-empty", "file-saver"], function (_exports, _computed, _controller, _object, _utils, _service, _dateUtils, _numberUtils, _objectUtils, _emberParachute, _emberChangeset, _emberChangesetValidations, _dateOrEmpty, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const {
    currency,
    geolib,
    Papa
  } = window;
  const queryParams = new _emberParachute.default({
    from: {
      defaultValue: '',
      refresh: true
    },
    to: {
      defaultValue: '',
      refresh: true
    },
    group: {
      defaultValue: '',
      refresh: true
    },
    locationAddress: {
      defaultValue: '',
      refresh: true
    },
    locationMiles: {
      defaultValue: '',
      refresh: true
    }
  });
  _exports.queryParams = queryParams;
  const filterValidation = {
    from: (0, _dateOrEmpty.default)(),
    to: (0, _dateOrEmpty.default)()
  };

  var _default = _controller.default.extend(queryParams.Mixin, {
    formatDateService: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    contactsExport: (0, _service.inject)(),
    store: (0, _service.inject)(),
    from: '',
    to: '',
    group: '',
    locationAddress: '',
    locationMiles: '',
    filterExpanded: false,
    filterChangeset: null,
    loading: false,
    error: null,
    cantFindLocationAddress: false,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{from,to,group,locationAddress,locationMiles}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false,
          error: null,
          cantFindLocationAddress: false
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.setProperties)(this, {
        loading: true,
        error: null,
        cantFindLocationAddress: false
      });
      let filter = {};
      let filterRecord = {};
      let addressFilterApplied = false;

      if ((0, _utils.isPresent)(queryParams.from)) {
        filter.from = queryParams.from;
        filterRecord.from = _dateUtils.default.deserializeDateOnly(queryParams.from);
      }

      if ((0, _utils.isPresent)(queryParams.to)) {
        filter.to = queryParams.to;
        filterRecord.to = _dateUtils.default.deserializeDateOnly(queryParams.to);
      }

      if ((0, _utils.isPresent)(queryParams.group)) {
        filter.group = queryParams.group;
        filterRecord.group = queryParams.group;
      }

      if ((0, _utils.isPresent)(queryParams.locationAddress) && (0, _utils.isPresent)(queryParams.locationMiles)) {
        filterRecord.locationAddress = queryParams.locationAddress;
        filterRecord.locationMiles = queryParams.locationMiles;
        addressFilterApplied = true;
      }

      let filterChangeset = new _emberChangeset.default(filterRecord, (0, _emberChangesetValidations.default)(filterValidation), filterValidation);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      return this.store.query('donor-by-time-frame', {
        filter: _objectUtils.default.decamelizeKeys(filter)
      }).then(records => {
        let {
          contactsCache
        } = this;
        (0, _object.set)(this, 'items', records.map(record => {
          return {
            contact: contactsCache.getById(record.contactId),
            record
          };
        }));

        if (addressFilterApplied) {
          return this.store.query('geocode', {
            filter: {
              address: filterRecord.locationAddress
            }
          }).then(records => {
            let geocode = records.firstObject;

            if (geocode) {
              let range = _numberUtils.default.milesToMeters(+filterRecord.locationMiles);

              let fromCoords = {
                latitude: geocode.latitude,
                longitude: geocode.longitude
              };
              let inRangeItems = this.items.filter(item => {
                let contactCoords = {
                  latitude: item.contact.latitude,
                  longitude: item.contact.longitude
                };
                return (item.contact.locationConfidence || 0) >= 0.3 && geolib.getDistance(fromCoords, contactCoords) < range;
              });
              (0, _object.set)(this, 'items', inRangeItems);
            } else {
              (0, _object.setProperties)(this, {
                items: [],
                cantFindLocationAddress: true
              });
            }

            (0, _object.set)(this, 'loading', false);
          });
        } else {
          (0, _object.set)(this, 'loading', false);
        }
      }, error => {
        (0, _object.set)(this, 'error', error);
      });
    },

    groupOptions: (0, _object.computed)('contactsCache.sortedGroups', function () {
      return ['', ...this.contactsCache.sortedGroups];
    }),
    locationMileOptions: [{
      label: '',
      val: ''
    }, {
      label: '25 miles',
      val: '25'
    }, {
      label: '50 miles',
      val: '50'
    }, {
      label: '75 miles',
      val: '75'
    }, {
      label: '100 miles',
      val: '100'
    }, {
      label: '200 miles',
      val: '200'
    }, {
      label: '300 miles',
      val: '300'
    }, {
      label: '400 miles',
      val: '400'
    }, {
      label: '500 miles',
      val: '500'
    }],
    filterMessage: (0, _object.computed)('filterChangeset.{from,to,group}', function () {
      let messages = [];
      let {
        formatDateService,
        filterChangeset
      } = this;
      let {
        from,
        to,
        group,
        locationAddress,
        locationMiles
      } = (0, _object.getProperties)(filterChangeset, 'from', 'to', 'group', 'locationAddress', 'locationMiles');

      if ((0, _utils.isPresent)(from)) {
        if ((0, _utils.isPresent)(to)) {
          messages.push(`Donations between ${formatDateService.format(from)} and ${formatDateService.format(to)}`);
        } else {
          messages.push(`Donations after ${formatDateService.format(from)}`);
        }
      } else if ((0, _utils.isPresent)(to)) {
        messages.push(`Donations before ${formatDateService.format(to)}`);
      }

      if ((0, _utils.isPresent)(group)) {
        messages.push(`Contacts in the "${group}" group`);
      }

      if ((0, _utils.isPresent)(locationAddress)) {
        messages.push(`Contacts within ${locationMiles} miles of '${locationAddress}'`);
      }

      if (messages.length === 0) {
        return 'All donations';
      }

      return messages.join(', ');
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.toggleExpanded();
        }
      }, {
        label: 'Create Group',
        svgJar: 'users',
        onClick: () => {
          this.send('createGroup');
        }
      }, {
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.send('download');
        }
      }];
    }),
    sortByProperty: 'record.totalDonations',
    sortByDirection: 'desc',
    sortBy: (0, _object.computed)('sortByProperty', 'sortByDirection', function () {
      let {
        sortByProperty,
        sortByDirection
      } = (0, _object.getProperties)(this, 'sortByProperty', 'sortByDirection');
      return [`${sortByProperty}:${sortByDirection}`];
    }),
    items: null,
    sortedItems: (0, _computed.sort)('items', 'sortBy'),
    itemsByStateSortBy: ['amount:desc'],
    itemsByState: (0, _object.computed)('items.[]', function () {
      let items = (0, _object.get)(this, 'items');
      let byState = {};
      items.forEach(item => {
        let state = (0, _object.get)(item, 'contact.state');

        if ((0, _utils.isEmpty)(state)) {
          state = 'Other';
        }

        state = state.trim();

        if (byState[state] == null) {
          byState[state] = 0;
        }

        byState[state] += (0, _object.get)(item, 'record.totalDonations');
      });
      return Object.keys(byState).map(state => {
        return {
          state: state,
          amount: byState[state]
        };
      });
    }),
    sortedItemsByState: (0, _computed.sort)('itemsByState', 'itemsByStateSortBy'),
    totalNumberDonations: (0, _object.computed)('items.[]', function () {
      return this.items.reduce((sum, x) => sum + x.record.numberDonations, 0);
    }),
    averageAverageDonation: (0, _object.computed)('items.[]', function () {
      if (this.items.length > 0) {
        return this.items.reduce((sum, x) => currency(sum).add(x.record.avgDonation).value, 0) / this.items.length;
      } else {
        return '';
      }
    }),
    totalTotalDonations: (0, _object.computed)('items.[]', function () {
      return this.items.reduce((sum, x) => sum + x.record.totalDonations, 0);
    }),
    updateSortBy: (0, _object.action)(function (property) {
      if (this.sortByProperty === property) {
        if (this.sortByDirection === 'asc') {
          (0, _object.set)(this, 'sortByDirection', 'desc');
        } else {
          (0, _object.set)(this, 'sortByDirection', 'asc');
        }
      } else {
        (0, _object.setProperties)(this, {
          sortByProperty: property,
          sortByDirection: 'asc'
        });
      }
    }),
    applyFilter: (0, _object.action)(function () {
      let {
        filterChangeset
      } = this;

      if (filterChangeset.get('isPristine')) {
        alert('No filter values have changed');
        return;
      }

      (0, _object.setProperties)(this, {
        from: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'from'), {
          invalidValue: ''
        }),
        to: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'to'), {
          invalidValue: ''
        }),
        group: (0, _object.get)(filterChangeset, 'group'),
        locationMiles: filterChangeset.get('locationMiles'),
        locationAddress: filterChangeset.get('locationAddress')
      });
    }),
    toggleExpanded: (0, _object.action)(function () {
      this.toggleProperty('filterExpanded');
    }),
    actions: {
      async download() {
        let rows = [];
        let headerRow = ['Contact', '# Donations', 'Average Donation', 'Last Donation Date', 'Last Donation Amount', 'Pledge Amount', 'Pledge Frequency', 'Total Donations', '---'];
        let headerInfo = await this.contactsExport.addContactsHeaderColumns(headerRow, this.contactsCache.contacts);
        rows.push(headerRow);

        for (let item of this.sortedItems) {
          var _item$record$pledge, _item$record$pledge2;

          let row = [item.contact.name, item.record.numberDonations, parseFloat(item.record.avgDonation.toString()).toFixed(2), item.record.lastDonationDate, item.record.lastDonationAmount, (_item$record$pledge = item.record.pledge) === null || _item$record$pledge === void 0 ? void 0 : _item$record$pledge.amount, (_item$record$pledge2 = item.record.pledge) === null || _item$record$pledge2 === void 0 ? void 0 : _item$record$pledge2.frequencyLabel, item.record.totalDonations, ''];
          await this.contactsExport.addContactInfoToRow(row, (0, _object.get)(item, 'contact'), headerInfo);
          rows.push(row);
        }

        this.store.createRecord('contact-download').save();
        let csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: "text/csv;charset=utf-8"
        });

        _fileSaver.default.saveAs(blob, 'donors-by-time-frame.csv', true);
      },

      createGroup() {
        let contactIds = (0, _object.get)(this, 'sortedItems').map(item => {
          return (0, _object.get)(item, 'contact.id');
        });
        this.transitionToRoute('funds.fund.groups.create', {
          queryParams: {
            contactIds: contactIds.join('-')
          }
        });
      }

    }
  });

  _exports.default = _default;
});