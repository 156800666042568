define("donor-elf-web/controllers/funds/fund/campaigns/campaign/index", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/filter-utils-new", "donor-elf-web/lib/object-utils", "@ember/utils"], function (_exports, _controller, _service, _object, _tracking, _emberCachedDecoratorPolyfill, _filterUtilsNew, _objectUtils, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PAGE_SIZE = 50;
  let CampaignsCampaignIndexController = (_class = class CampaignsCampaignIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "campaignService", _descriptor, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor2, this);

      _initializerDefineProperty(this, "journalService", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _defineProperty(this, "model", void 0);

      _defineProperty(this, "queryParams", ['page', 'filters']);

      _initializerDefineProperty(this, "page", _descriptor5, this);

      _initializerDefineProperty(this, "filters", _descriptor6, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor7, this);

      _initializerDefineProperty(this, "loading", _descriptor8, this);

      _initializerDefineProperty(this, "lineItems", _descriptor9, this);

      _initializerDefineProperty(this, "totalPages", _descriptor10, this);

      _initializerDefineProperty(this, "totalAmount", _descriptor11, this);
    }

    get journals() {
      return this.journalService.journals.filter(j => j.campaignId === this.model.campaign.id);
    }

    templateDidInsert() {
      if ((0, _utils.isEmpty)(this.filters)) {
        this.filters = this.defaultFilters;
      }

      this.deserializeFilters();
      this.load();
    }

    templateWillDestroy() {
      this.loading = true;
      this.filters = undefined;
      this.appliedFilters = undefined;
      this.page = 1;
      this.lineItems = [];
    }

    async load() {
      this.loading = true;
      let queryFilter = {
        campaignId: this.model.campaign.id,
        offset: (this.page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE
      };
      let {
        availablePropertyFilters
      } = this;

      for (let appliedFilter of this.appliedFilters || []) {
        let propertyFilter = availablePropertyFilters.find(x => x.propertyName === appliedFilter.propertyName);
        await propertyFilter.applyToQueryFilter(appliedFilter, queryFilter);
      }

      let records = await this.store.query('line-item', {
        filter: _objectUtils.default.decamelizeKeys(queryFilter)
      });
      let totalCount = Number(records.meta.total_count || 0);
      this.totalPages = Math.ceil(totalCount / PAGE_SIZE);
      this.totalAmount = Number(records.meta.total_amount);
      this.lineItems = records.toArray();
      this.loading = false;
    }

    get availablePropertyFilters() {
      let list = [];
      list.push({
        propertyName: 'transDate',
        label: 'Date',
        propertyType: 'date',
        availableOperations: ['><', '>=', '<=', '='],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          if (appliedFilter.operation === '>=') {
            queryFilter.from = appliedFilter.val;
          } else if (appliedFilter.operation === '<=') {
            queryFilter.to = appliedFilter.val;
          } else if (appliedFilter.operation === '><') {
            let dates = appliedFilter.val;
            queryFilter.from = dates[0];
            queryFilter.to = dates[1];
          } else {
            queryFilter.date = appliedFilter.val;
          }
        }
      });
      list.push({
        propertyName: 'contactIds',
        label: 'Contact',
        propertyType: 'contactIds',
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          queryFilter.contactIds = appliedFilter.val;
        }
      });
      return list;
    }

    get defaultFilters() {
      let appliedFilters = [];

      if (this.fundGoalService.hasYtdGoalType) {
        appliedFilters.push({
          propertyName: 'transDate',
          operation: '>=',
          val: this.fundGoalService.ytdStartMoment.format('YYYY-MM-DD')
        });
      }

      return (0, _filterUtilsNew.serializeAppliedFilters)(appliedFilters);
    }

    handleFiltersChanged(currentFilters) {
      this.page = 1;
      this.appliedFilters = currentFilters;
      this.serializeFilters();
      this.load();
    }

    changePage(pageNumber) {
      this.page = pageNumber;
      this.load();
    }

    serializeFilters() {
      this.filters = (0, _filterUtilsNew.serializeAppliedFilters)(this.appliedFilters);
    }

    deserializeFilters() {
      this.appliedFilters = (0, _filterUtilsNew.deserializeAppliedFilters)(this.filters);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "appliedFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "lineItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "totalPages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "totalAmount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "availablePropertyFilters", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "availablePropertyFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFiltersChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFiltersChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePage"), _class.prototype)), _class);
  _exports.default = CampaignsCampaignIndexController;
});