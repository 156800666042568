define("donor-elf-web/components/task/info/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/debug"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div 
    class='tw-shadow-md rounded'
    ...attributes
  >
    {{#if this.editing}}
      <Task::Info::Edit
        @task={{@task}}
        @changeset={{@changeset}}
        @displayJournalInfo={{this.displayJournalInfo}}
        @hideSaveAndCancel={{@hideSaveAndCancel}}
        @showCopyIcon={{@showCopyIcon}}
        @copyAndPasteAllConfirmation={{@copyAndPasteAllConfirmation}}
        @saveImmediately={{@saveImmediately}}
        @inAddModal={{@inAddModal}}
        @hideAddAnother={{@hideAddAnother}}
        @onCopyTask={{@onCopyTask}}
        @onTaskSaving={{@onTaskSaving}}
        @onTaskSaveError={{@onTaskSaveError}}
        @onTaskSaved={{this.handleTaskSaved}}
        @onCancel={{this.handleEditCancelled}}
        @onTaskDeleted={{this.handleTaskDeleted}}
      />
    {{else}}
      <Task::Info::Show
        @task={{@task}}
        @displayJournalInfo={{this.displayJournalInfo}}
        @onTaskSaving={{@onTaskSaving}}
        @onTaskSaveError={{@onTaskSaveError}}
        @onTaskSaved={{this.handleTaskSaved}}
        @onEditTask={{this.handleEditTask}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "/SwfuW9R",
    "block": "[[[11,0],[24,0,\"tw-shadow-md rounded\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"editing\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@task\",\"@changeset\",\"@displayJournalInfo\",\"@hideSaveAndCancel\",\"@showCopyIcon\",\"@copyAndPasteAllConfirmation\",\"@saveImmediately\",\"@inAddModal\",\"@hideAddAnother\",\"@onCopyTask\",\"@onTaskSaving\",\"@onTaskSaveError\",\"@onTaskSaved\",\"@onCancel\",\"@onTaskDeleted\"],[[30,2],[30,3],[30,0,[\"displayJournalInfo\"]],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,0,[\"handleTaskSaved\"]],[30,0,[\"handleEditCancelled\"]],[30,0,[\"handleTaskDeleted\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@task\",\"@displayJournalInfo\",\"@onTaskSaving\",\"@onTaskSaveError\",\"@onTaskSaved\",\"@onEditTask\"],[[30,2],[30,0,[\"displayJournalInfo\"]],[30,11],[30,12],[30,0,[\"handleTaskSaved\"]],[30,0,[\"handleEditTask\"]]]],null],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@task\",\"@changeset\",\"@hideSaveAndCancel\",\"@showCopyIcon\",\"@copyAndPasteAllConfirmation\",\"@saveImmediately\",\"@inAddModal\",\"@hideAddAnother\",\"@onCopyTask\",\"@onTaskSaving\",\"@onTaskSaveError\"],false,[\"if\",\"task/info/edit\",\"task/info/show\"]]",
    "moduleName": "donor-elf-web/components/task/info/index.hbs",
    "isStrictMode": false
  });

  let TaskInfoIndexComponet = (_class = class TaskInfoIndexComponet extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "taskService", _descriptor, this);

      _initializerDefineProperty(this, "journalService", _descriptor2, this);

      _initializerDefineProperty(this, "_editing", _descriptor3, this);

      (false && !(typeof args.onTaskDeleted === 'function') && (0, _debug.assert)('@onTaskDeleted() argument must be present for Task::Info', typeof args.onTaskDeleted === 'function'));
    }

    get displayJournalInfo() {
      var _this$args$displayJou;

      return (_this$args$displayJou = this.args.displayJournalInfo) !== null && _this$args$displayJou !== void 0 ? _this$args$displayJou : true;
    }

    get editing() {
      return this.args.onlyEdit || this._editing || this.args.task.isNew;
    }

    handleEditTask() {
      this._editing = true;
    }

    handleTaskSaved(task, options) {
      var _this$args$onTaskSave, _this$args;

      this._editing = false;
      (_this$args$onTaskSave = (_this$args = this.args).onTaskSaved) === null || _this$args$onTaskSave === void 0 ? void 0 : _this$args$onTaskSave.call(_this$args, task, options);
    }

    handleEditCancelled() {
      var _this$args$onCancelEd, _this$args2;

      this._editing = false;
      (_this$args$onCancelEd = (_this$args2 = this.args).onCancelEditTask) === null || _this$args$onCancelEd === void 0 ? void 0 : _this$args$onCancelEd.call(_this$args2, this.args.task);
    }

    handleTaskDeleted(task) {
      this._editing = false;
      this.args.onTaskDeleted(task);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_editing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleEditTask", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleEditTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEditCancelled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleEditCancelled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskDeleted"), _class.prototype)), _class);
  _exports.default = TaskInfoIndexComponet;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaskInfoIndexComponet);
});