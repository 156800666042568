define("donor-elf-web/lib/store-utils", ["exports", "rsvp", "@ember/debug"], function (_exports, _rsvp, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryWithPages = queryWithPages;
  _exports.queryWithPagesAndLocalCache = queryWithPagesAndLocalCache;
  const memoryCache = {}; // args:
  // - store
  // - modelName
  // - queryOptions
  // - pageSize - default to 50
  // - pageCallback - function that is called after every page of records are downloaded
  //   - function({ pageRecords, downloadedCount, totalCount})
  //
  // Returns:
  // promise that resolves with array of all records
  //
  // It's expecting the server response to include meta.total_count = total number of records

  function queryWithPages(args) {
    var _args$pageSize, _args$queryOptions, _queryOptions$filter;

    let pageSize = (_args$pageSize = args.pageSize) !== null && _args$pageSize !== void 0 ? _args$pageSize : 50;
    let queryOptions = (_args$queryOptions = args.queryOptions) !== null && _args$queryOptions !== void 0 ? _args$queryOptions : {};
    queryOptions.filter = (_queryOptions$filter = queryOptions.filter) !== null && _queryOptions$filter !== void 0 ? _queryOptions$filter : {};
    queryOptions.filter.limit = pageSize;
    return new _rsvp.Promise((resolve, reject) => {
      queryPage({
        promiseResolve: resolve,
        promiseReject: reject,
        store: args.store,
        modelName: args.modelName,
        queryOptions,
        records: [],
        page: 1,
        pageSize,
        pageCallback: args.pageCallback
      });
    });
  } // Uses memory and localStorage to cache records from the server.
  //
  // If the records are already cached in memory, it returns immediately and records are NOT retrieved from the server
  //
  // If the records are not cached in memory but in localStorage, it:
  //  - saves the records in the memory cache
  //  - returns immediately with the cached records
  //  - retrieves fresh records from the server and calls 'onRecordsFromServer' when the fresh records come back from the server
  //  - it saves the fresh records from the server in both memory and localStorage cache
  //
  // args:
  // - cacheKey - the cache key to use. Make sure it's unique between different funds
  // - cacheLocalWhenRecordLengthGreaterThan - when record length is greater, then results are cached in local storage
  // - onRecordsFromServer - this is called with the fresh records returned from the server
  //   - not called if there are no cached records
  // - the rest of the args are the same as `queryWithPages` above
  //
  // Returns:
  // - when cached values exist, it returns the cached values
  // - when cached values don't exist, it returns the records from the server


  function queryWithPagesAndLocalCache(args) {
    let promise;
    let serializedData;
    let realCacheKey = `store-utils-${args.cacheKey}`;
    serializedData = memoryCache[realCacheKey];
    let hasMemoryCachedRecords = serializedData != null;

    if (!hasMemoryCachedRecords) {
      serializedData = localStorage.getItem(realCacheKey);
    }

    let hasCachedRecords = serializedData != null; // if the records have already been cached, then resolve the promise immediately with the cached records

    if (hasCachedRecords) {
      try {
        let serializedArray = JSON.parse(serializedData);
        let records = [];
        serializedArray.forEach(data => {
          let record = args.store.push(args.store.normalize(args.modelName, data.data));
          records.push(record);
        });
        promise = new _rsvp.Promise(resolve => {
          resolve(records);
        });
      } catch (e) {
        hasCachedRecords = false;
      }
    }

    if (!hasMemoryCachedRecords) {
      // now get the fresh records from the server
      let serverPromise = queryWithPages({
        store: args.store,
        modelName: args.modelName,
        queryOptions: args.queryOptions,
        pageSize: args.pageSize,
        pageCallback: hasCachedRecords ? undefined : args.pageCallback
      }).then(serverRecords => {
        // always cache the fresh server records in memory
        memoryCache[realCacheKey] = JSON.stringify(serverRecords.map(x => {
          let serialized = x.serialize({
            includeId: true
          });
          serialized.data.type = args.modelName;
          return serialized;
        }));

        if (serverRecords.length > args.cacheLocalWhenRecordLengthGreaterThan) {
          try {
            localStorage.setItem(realCacheKey, memoryCache[realCacheKey]);
          } catch (e) {// do nothing
          }
        } else {
          try {
            localStorage.removeItem(realCacheKey);
          } catch (e) {// do nothing
          }
        }

        if (hasCachedRecords) {
          args.onRecordsFromServer({
            records: serverRecords
          });
        }

        return serverRecords;
      });

      if (!hasCachedRecords) {
        promise = serverPromise;
      }
    }

    return promise;
  }

  function queryPage(args) {
    args.queryOptions.filter.offset = (args.page - 1) * args.pageSize;
    args.store.query(args.modelName, args.queryOptions).then(results => {
      var _results$meta, _args$pageCallback;

      let totalCount = (_results$meta = results.meta) === null || _results$meta === void 0 ? void 0 : _results$meta.total_count;
      (false && !(totalCount != null) && (0, _debug.assert)(`${args.modelName} query is expected to return meta.total_count`, totalCount != null));
      let pageRecords = results.toArray();
      args.records.push(...pageRecords);
      (_args$pageCallback = args.pageCallback) === null || _args$pageCallback === void 0 ? void 0 : _args$pageCallback.call(args, {
        pageRecords,
        downloadedCount: args.records.length,
        totalCount: totalCount
      });

      if (pageRecords.length === args.pageSize) {
        queryPage({
          promiseResolve: args.promiseResolve,
          promiseReject: args.promiseReject,
          store: args.store,
          modelName: args.modelName,
          queryOptions: args.queryOptions,
          records: args.records,
          page: args.page + 1,
          pageSize: args.pageSize,
          pageCallback: args.pageCallback
        });
      } else {
        args.promiseResolve(args.records);
      }
    }, error => {
      args.promiseReject(error);
    });
  }
});