define("donor-elf-web/components/journal/report/raised-chart", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "donor-elf-web/lib/date-utils"], function (_exports, _component, _templateFactory, _component2, _object, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert (fn this.renderChart true)}}
    {{did-update (fn this.renderChart false) @intervalCumulativeAmounts}}
  />
  */
  {
    "id": "cU89vbHG",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"renderChart\"]],true],null]],null],[4,[38,2],[[28,[37,1],[[30,0,[\"renderChart\"]],false],null],[30,1]],null],[12],[13]],[\"@intervalCumulativeAmounts\"],false,[\"did-insert\",\"fn\",\"did-update\"]]",
    "moduleName": "donor-elf-web/components/journal/report/raised-chart.hbs",
    "isStrictMode": false
  });

  const {
    Highcharts,
    moment
  } = window;
  let JournalRaisedChartComponent = (_class = class JournalRaisedChartComponent extends _component2.default {
    renderChart(animation, element) {
      Highcharts.chart(element, this.getChartSettings({
        animation
      }));
    }

    get journalGoalDateUtc() {
      return (0, _dateUtils.dateToUtc)(this.args.journal.goalDate);
    }

    get seriesStartUtc() {
      let firstIntervalStartDate = _dateUtils.default.deserializeDateOnly(this.args.intervalDates[0].start);

      let date = moment(firstIntervalStartDate).add({
        days: -1
      }).toDate();
      return (0, _dateUtils.dateToUtc)(date);
    }

    get totalDecisionAmount() {
      var _this$args$intervalCu;

      return ((_this$args$intervalCu = this.args.intervalCumulativeAmounts[this.args.intervalCumulativeAmounts.length - 1]) === null || _this$args$intervalCu === void 0 ? void 0 : _this$args$intervalCu.decision) || 0;
    }

    get goalSeries() {
      let {
        journalGoalDateUtc,
        seriesStartUtc,
        args: {
          journalGoal
        }
      } = this;
      return {
        name: 'Goal',
        type: 'line',
        color: '#f0ad4e',
        lineWidth: 4,
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, journalGoal.amount], [journalGoalDateUtc, journalGoal.amount]]
      };
    }

    get onTrackSeries() {
      let {
        seriesStartUtc,
        journalGoalDateUtc,
        args: {
          journalGoal
        }
      } = this;
      return {
        name: 'On Track Goal',
        type: 'line',
        color: '#eceeef',
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, 0], [journalGoalDateUtc, journalGoal.amount]]
      };
    }

    get decisionsSeries() {
      let {
        seriesStartUtc,
        args: {
          intervalCumulativeAmounts,
          journal: {
            decisionsRequireGift
          }
        }
      } = this;
      return {
        name: 'All Decisions',
        type: decisionsRequireGift ? 'line' : 'area',
        color: '#8ac7ec',
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, 0]].concat(intervalCumulativeAmounts.map(item => {
          let date = _dateUtils.default.deserializeDateOnly(item.end);

          let amount = item.decision; // There's an edge case where a decision has a pledge with a gift that
          // comes in before the decision date, and that makes the decisionAmount less than the 
          // the withGift amount. So we're preventing that here

          if (item.decision < item.withGift) {
            amount = item.withGift;
          }

          return [(0, _dateUtils.dateToUtc)(date), amount];
        }))
      };
    }

    get withGiftSeries() {
      let {
        seriesStartUtc,
        args: {
          intervalCumulativeAmounts
        }
      } = this;
      return {
        name: 'Decisions with Gifts',
        type: 'area',
        color: '#0b68a1',
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, 0]].concat(intervalCumulativeAmounts.map(item => {
          let date = _dateUtils.default.deserializeDateOnly(item.end);

          return [(0, _dateUtils.dateToUtc)(date), item.withGift];
        }))
      };
    }

    getChartSettings({
      animation
    }) {
      let {
        totalDecisionAmount,
        args: {
          journalGoal,
          chartHeight
        }
      } = this;
      let series = [];
      series.push(this.decisionsSeries);
      series.push(this.withGiftSeries);
      series.push(this.onTrackSeries);
      series.push(this.goalSeries);
      return {
        chart: {
          height: chartHeight,
          margin: [2, 0, 2, 0],
          borderWidth: 0
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          },
          // tickInterval: 1000 * 60 * 60 * 24 * 7, // 1 week;
          // tickPositions: [
          //   Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
          // ].concat(intervalDates.map(dateInfo => {
          //   let date = dateUtils.deserializeDateOnly(dateInfo.end);
          //   return [Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())];
          // })),
          // tickPosition: 'inside',
          startOnTick: false
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false
          },
          max: Math.max(journalGoal.amount, totalDecisionAmount),
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0]
        },
        plotOptions: {
          series: {
            animation: animation !== null && animation !== void 0 ? animation : true
          }
        },
        legend: {
          enabled: false
        },
        series
      };
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype)), _class);
  _exports.default = JournalRaisedChartComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JournalRaisedChartComponent);
});